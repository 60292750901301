import React, { useState, useRef } from "react"

import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from "gatsby-source-contentful/rich-text"

import Modal from "react-modal"

import Text from "../../components/UI/Text"
import InputField from "../../components/Forms/InputField"
import PhoneField from "../../components/Forms/PhoneField"
import BriefStepButtons from "./BriefStepButtons"
import AttachFieldTips from "./AttachFieldTips"

import useFieldTips from "../../hooks/useFieldTips"

interface BriefContactProps {
  show: boolean
  content: RenderRichTextData<ContentfulRichTextGatsbyReference>
  formFunctions: any
  handleBackStep: () => void
  handleNextStep: () => void
}

const defaultProps = {}

const BriefContact: React.FC<BriefContactProps> = props => {
  if (!props.show) return null

  // setup Modal
  Modal.setAppElement("#___gatsby")

  // hooks
  const { fieldTips, fieldTipTargets } = useFieldTips()

  // react-hook-form
  const trigger = props.formFunctions.trigger

  // state
  const [activeTip, setActiveTip] = useState("")
  const [tipOffset, setTipOffset] = useState(0)

  // refs
  const formRef = useRef<HTMLDivElement>(null)

  // events
  const nextStep = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const result = await trigger()
    if (result) props.handleNextStep()
  }

  const handleOpenTip = (value: string, offsetTop: number) => {
    const formOffset = formRef.current?.getBoundingClientRect().top || 0
    setTipOffset(offsetTop - formOffset)
    setActiveTip(value)
  }

  const handleCloseTip = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setActiveTip("")
  }

  // field names in this section
  const fieldNames = ["First Name", "Last Name", "Phone", "Email"]

  return (
    <div className="grid-x grid-margin-x">
      <div className="cell large-6">
        <div className="brief__main" ref={formRef}>
          <div className="briefStep_content">
            <Text content={props.content} />
          </div>

          <div className="briefStep_form">
            <InputField
              formFunctions={props.formFunctions}
              label="First Name"
              required
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("First Name") !== -1}
            />

            <InputField
              formFunctions={props.formFunctions}
              label="Last Name"
              required
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("Last Name") !== -1}
            />

            <PhoneField
              formFunctions={props.formFunctions}
              label="Phone"
              required
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("Phone") !== -1}
            />

            <InputField
              formFunctions={props.formFunctions}
              type="email"
              label="Email"
              required
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("Email") !== -1}
            />
          </div>

          <BriefStepButtons
            handleBackStep={props.handleBackStep}
            handleNextStep={nextStep}
          />
        </div>
      </div>

      <div className="cell large-6">
        <div className="brief__sidebar">
          <AttachFieldTips
            fieldNames={fieldNames}
            fieldTips={fieldTips}
            activeTip={activeTip}
            tipOffset={tipOffset}
            handleCloseTip={handleCloseTip}
          />
        </div>
      </div>
    </div>
  )
}

BriefContact.defaultProps = defaultProps

export default BriefContact
