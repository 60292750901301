import React, { useState, useEffect } from "react"
import Uppy from "@uppy/core"
import { Dashboard, useUppy } from "@uppy/react"
import DragDrop from "@uppy/drag-drop"
import XHRUpload from "@uppy/xhr-upload"
//import axios from "axios"

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"
import axios from "axios"

interface UppyFieldProps {
  formFunctions: any
  id?: string
  name?: string
  label: string
  type: string
  handleOpenTip?: (value: string, offsetTop: number) => void | undefined
  hasTooltip?: boolean
}

const defaultProps = {
  id: undefined,
  type: undefined,
  name: undefined,
  label: undefined,
  handleOpenTip: undefined,
}

const UppyField: React.FC<UppyFieldProps> = props => {
  // react-hook-form
  const errors = props.formFunctions.errors

  // state
  const [showValidation, setShowValidation] = useState(false)

  // effects
  useEffect(() => {
    if (!showValidation && Object.keys(errors).length) {
      setShowValidation(true)
    }
  }, [errors])

  const uppy = useUppy(() => {
    return new Uppy({
      autoProceed: true,
      allowMultipleUploads: true,

    })
      .use(XHRUpload, {
        endpoint: `/api/upload?id=${props.id}&type=${props.type}`,
      })
      .use(DragDrop, {
        // Options
      })
      .on('file-removed', (file, reason) => {
        // have to do something with file but don't care
        if (reason === 'removed-by-user') {
          axios(`/api/upload?id=${props.id}&type=${props.type}&delete=${file.name}`).catch(err => { console.log(err) });
        }
      })
      .on("upload-success", (file, response) => {
        // have to do something with file but don't care
        file.id
        props.formFunctions.setValue(
          props.name,
          `https://drive.google.com/drive/folders/${response.body.folderId}`
        )
      })
  })

  // events
  const openTip = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const offsetTop =
      document.getElementById(input_id)?.getBoundingClientRect().top || 0
    if (props.handleOpenTip) props.handleOpenTip(input_name, offsetTop)
  }

  const input_name = props.name || props.label
  const input_id = input_name.split(" ").join("_")

  return (
    <div className="form-field">
      <div className="field__input--uppy" style={{ verticalAlign: "top" }}>
        <Dashboard uppy={uppy} id={input_id} showRemoveButtonAfterComplete={true} />
      </div>

      {errors.hasOwnProperty(input_name) && (
        <>
          <p className="error-msg">{errors[input_name].message}</p>
          <span className="field-error" />
        </>
      )}

      {showValidation && !errors.hasOwnProperty(input_name) && (
        <span className="field-valid" />
      )}

      {!showValidation && props.hasTooltip && (
        <button className="field-tip" onClick={openTip} />
      )}
    </div>
  )
}

UppyField.defaultProps = defaultProps

export default UppyField
