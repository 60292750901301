import React from "react"

interface BriefStepButtonsProps {
  handleBackStep: () => void
  handleNextStep: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const defaultProps = {}

const BriefStepButtons: React.FC<BriefStepButtonsProps> = props => {
  return (
    <div className="briefStep__cta">
      <div className="grid-x align-justify">
        <div className="cell shrink medium-order-2">
          <button className="button" onClick={props.handleNextStep}>
            Next
          </button>
        </div>
        <div className="cell shrink">
          <button className="button button--alt" onClick={props.handleBackStep}>
            Back
          </button>
        </div>
      </div>
    </div>
  )
}

BriefStepButtons.defaultProps = defaultProps

export default BriefStepButtons
