import { useStaticQuery, graphql } from "gatsby"

export const useConfig = () => {
  const config = useStaticQuery(
    graphql`
      query {
        mainMenu: contentfulConfig(entryName: { eq: "Config" }) {
          mainMenu {
            ... on ContentfulMenu {
              ...MenuFields
            }
          }
        }

        footer: contentfulConfig(entryName: { eq: "Config" }) {
          footerMenu {
            ... on ContentfulMenu {
              ...MenuFields
            }
          }
          footerContent {
            raw
            references {
              ...ContentfulPageLinkFields
            }
          }
        }

        forms: contentfulConfig(entryName: { eq: "Config" }) {
          briefForm {
            ...BriefFormFields
          }
        }

        images: contentfulConfig(entryName: { eq: "Config" }) {
          defaultCaseStudyImage {
            ...MediaImageFields
            gatsbyImageData(
              width: 570
              height: 390
              placeholder: BLURRED
              quality: 95
            )
          }
        }

        social: contentfulConfig(entryName: { eq: "Config" }) {
          facebook
          instagram
          twitter
          linkedIn
          youTube
        }

        contact: contentfulConfig(entryName: { eq: "Config" }) {
          phone
          email
        }

        seo: contentfulConfig(entryName: { eq: "Config" }) {
          defaultShareImage {
            ...MediaImageFields
            gatsbyImageData(
              width: 1600
              height: 900
              placeholder: BLURRED
              quality: 95
            )
          }
          metaDescription {
            metaDescription
          }
        }
        zoho: contentfulConfig(entryName: { eq: "Config" }) {
          bookingUrl
        }
      }
    `
  )

  return {
    defaultImages: {
      caseStudy: config.images.defaultCaseStudyImage,
    },

    mainMenu: config.mainMenu.mainMenu,

    footer: {
      footerMenu: config.footer.footerMenu,
      footerContent: config.footer.footerContent,
    },

    forms: {
      briefForm: config.forms.briefForm,
    },

    social: {
      facebook: config.social.facebook,
      instagram: config.social.instagram,
      twitter: config.social.twitter,
      linkedIn: config.social.linkedIn,
      youTube: config.social.youTube,
    },

    contact: {
      phone: config.contact.phone,
      email: config.contact.email,
    },

    seo: {
      defaultShareImage: config.seo.defaultShareImage,
      metaDescription: config.seo.metaDescription?.metaDescription || "",
    },
    zoho: {
      bookingUrl: config.zoho.bookingUrl
    }
  }
}

export default useConfig
