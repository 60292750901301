/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

/****************************************************
	Site styles
****************************************************/
import "./src/scss/app.scss"

/****************************************************
	Page transition 
	- fix scroll jump on page transition
****************************************************/
export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  const locationPath = location.pathname
  const prevLocationPath = prevRouterProps?.location?.pathname
  const savedPosition = getSavedScrollPosition(location) || [0, 0]

  // only scroll top if the page path has changed - not on query string update
  if (locationPath !== prevLocationPath) {
    window.scrollTo({
      top: savedPosition[1],
      left: savedPosition[0],
      behavior: "smooth",
    })
  }
  return false
}
