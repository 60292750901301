import React, { useRef } from "react"
import { graphql } from "gatsby"

import SlickSlider from "react-slick"

import Slide from "./Slide"

import { SliderModel } from "../../models/blocks"

export interface SliderProps extends SliderModel {}

const defaultProps = {}

const Slider: React.FC<SliderProps> = props => {
  const sliderRef = useRef<SlickSlider>(null)
  //console.log("Slider: ", props)

  const sliderSettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
        },
      },
    ],
  }

  const next = () => sliderRef?.current?.slickNext()
  const previous = () => sliderRef?.current?.slickPrev()

  if (!props.slides) return null

  const aosClass = `aos-${Math.floor(Math.random() * 100000)}`

  return (
    <section className={`slider`}>
      <div className={`grid-container ${aosClass}`}>
        <SlickSlider {...sliderSettings} ref={sliderRef}>
          {props.slides.map((slide, index: number) => (
            <Slide
              key={slide.id}
              {...slide}
              next={next}
              previous={previous}
              index={index}
            />
          ))}
        </SlickSlider>
      </div>
    </section>
  )
}

Slider.defaultProps = defaultProps

export default Slider

export const query = graphql`
  fragment SliderFields on ContentfulBlockSlider {
    contentful_id
    id
    slides {
      ...CalloutFields
    }
    internal {
      type
    }
  }
`
