import React from "react"

import Modal from "react-modal"

import FieldTip from "./FieldTip"

import { ToolTipModel } from "../../models/components"

interface AttachFieldTipsProps {
  fieldNames: string[]
  fieldTips: ToolTipModel[]
  activeTip: string
  tipOffset: number
  handleCloseTip: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const defaultProps = {}

const AttachFieldTips: React.FC<AttachFieldTipsProps> = props => {
  // setup Modal
  Modal.setAppElement("#___gatsby")

  return (
    <>
      {props.fieldTips
        .filter((x: ToolTipModel) => props.fieldNames.includes(x.target))
        .map((x: ToolTipModel) => (
          <div key={`toolTip_${x.target}`}>
            <Modal
              isOpen={props.activeTip === x.target}
              contentLabel="form tooltip"
              className="fieldTip__modal"
              overlayClassName="fieldTip__overlay hide-for-large"
            >
              <FieldTip
                content={x.content}
                active={true}
                tipOffset={0}
                handleCloseTip={props.handleCloseTip}
              />
            </Modal>

            <FieldTip
              content={x.content}
              active={props.activeTip === x.target}
              tipOffset={props.tipOffset}
              handleCloseTip={props.handleCloseTip}
              className="show-for-large"
            />
          </div>
        ))}
    </>
  )
}

AttachFieldTips.defaultProps = defaultProps

export default AttachFieldTips
