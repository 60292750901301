import React, { useRef } from "react"
import { Link } from "gatsby"

import SimpleBar from "simplebar-react"

import LogoGreen from "../../images/svg/aglo_logo.svg"
import CloseIcon from "../../images/svg/icon_close.svg"
import PhoneIcon from "../../images/svg/icon_phone.svg"
import MailIcon from "../../images/svg/icon_mail.svg"

import MenuLink from "./MenuLink"

import useConfig from "../../hooks/useConfig"

import { MenuItemModel } from "../../models/menu"

interface MobMenuProps {
  handleToggleMobMenu: () => void
}

const MobMenu: React.FC<MobMenuProps> = props => {
  // hooks
  const { mainMenu } = useConfig()

  // refs
  const scrollRef = useRef<SimpleBar>(null)

  return (
    <aside className="mobMenu Light hide-for-large">
      <header>
        <div className="header__wrapper">
          <div className="grid-x grid-margin-x align-middle">
            <div className="cell shrink">
              <Link to="/" className="header__logo">
                <LogoGreen />
              </Link>
            </div>

            <div className="cell auto hide-for-large">
              <div className="header__mobActions">
                <button
                  className="header__menuBtn"
                  onClick={props.handleToggleMobMenu}
                >
                  <CloseIcon stroke={"#1C1B20"} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <SimpleBar
        style={{ maxHeight: "calc(100vh - 100px)" }}
        scrollableNodeProps={{ ref: scrollRef }}
      >
        <div className="mobMenu__wrapper">
          <div className="mobMenu__menu">
            {mainMenu?.menuItems?.map((menuItem: MenuItemModel) => (
              <div className="grid-x align-middle align-center mobMenu__item">
                <div className="cell shrink">
                  <MenuLink {...menuItem} className="mobMenu__menuLink" />
                </div>
              </div>
            ))}

            <div className="grid-x align-middle align-center mobMenu__item">
              <div className="cell shrink">
                <PhoneIcon fill="#34a853" />
              </div>
              <div className="cell shrink">
                <a href="tel:+61291386220" className="mobMenu__menuLink">
                  +61 2 9138 6220
                </a>
              </div>
            </div>

            <div className="grid-x align-middle align-center mobMenu__item">
              <div className="cell shrink">
                <MailIcon fill="#34a853" />
              </div>
              <div className="cell shrink">
                <a href="mailto:hello@aglo.co" className="mobMenu__menuLink">
                  hello@aglo.co
                </a>
              </div>
            </div>
          </div>
        </div>
      </SimpleBar>
    </aside>
  )
}

export default MobMenu
