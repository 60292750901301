import React from "react"
import { Link } from "gatsby"

import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

import TagList from "../../../components/TagList"

import * as utils from "../../../utils"

import { CaseStudyModel } from "../../../models/pages"

export interface CaseStudyListingProps extends CaseStudyModel {
  className?: string
  setTagParam: any
  aos?: string | undefined
  aosDelay?: number | undefined
  aosAnchor?: string | undefined
}

const defaultProps = {
  className: "",
  aos: undefined,
  aosDelay: undefined,
  aosAnchor: undefined,
}

const CaseStudyCard: React.FC<CaseStudyListingProps> = props => {
  const image = getImage(props.listingImage?.gatsbyImageData)

  // events
  // const handleTagSelect = (
  //   e: React.MouseEvent<HTMLButtonElement>,
  //   value: string
  // ) => {
  //   e.preventDefault()
  //   e.stopPropagation()
  //   props.setTagParam(value)
  // }

  return (
    <div
      className={`card--caseStudy ${props.className}`}
      //data-aos="flip-left"
      //data-aos-duration="500"
      data-aos={props.aos}
      //data-aos-delay={utils.aosBaseDelay}
      data-aos-delay={props.aosDelay}
      //data-aos-anchor={`.${aosClass}`}
      data-aos-anchor={props.aosAnchor}
    >
      <Link to={utils.getPageSlugWithPrefix(props)} className="card__link">
        <div
          className="card__image"
          // data-aos="flip-left"
          // //data-aos-duration="500"
          // data-aos-delay={utils.aosBaseDelay + 100}
          // data-anchor={`.${aosClass}`}
        >
          <BgImage image={image}>
            <div className="card__imageSpacer" />
          </BgImage>
        </div>
        <div
          className="card__content"
          // data-aos="fade-up"
          // data-aos-delay={props.aosDelay}
          // data-aos-anchor={props.aosAnchor}
        >
          <h3 className="card__title">{props.heading}</h3>

          <TagList tags={props.tags} />
        </div>
      </Link>
    </div>
  )
}

CaseStudyCard.defaultProps = defaultProps

export default CaseStudyCard
