/****************************************************

	Utilities - Links

****************************************************/

/******************************************************
	make the URL pretty
	- remove double slashes
	- replace spaces with hyphens
	- remove double hyphens
	- make lowercase
******************************************************/
export const getPrettyURL = (url: string) => {
  if (!url) return ""
  return url
    .replace(/\/\//g, "/")
    .replace(/ /g, "-")
    .replace(/-+/g, "-")
    .toLowerCase()
}

/******************************************************
	get link (URL / Entry / Asset)
******************************************************/
export const getCtaLink = (url: string = "", entry: any, asset: any) => {
  // asset
  if (asset) return { url: asset.file.url, isExternal: true }

  // CMS entry
  if (entry) return { url: getPageSlugWithPrefix(entry), isExternal: false }

  // URL
  if (url && url.trim().length)
    return { url: url, isExternal: !/^\/(?!\/)/.test(url) }

  return { url: null, isExternal: false }
}

/******************************************************
	get content type link prefix
******************************************************/
export const getPageSlugWithPrefix = (data: any) => {
  if (!data || !data.slug) return "/"

  let url
  switch (data?.internal?.type) {
    case "ContentfulPageCaseStudy":
      url = `/our-work/${data.slug}`
      break
    default:
      url = `/${data.slug}`
  }

  return getPrettyURL(url)
}
