import * as React from "react"
import { graphql } from "gatsby"

import ReactPlayer from "react-player"

import { VideoModel } from "../models/components"

export interface VideoProps extends VideoModel {
  className?: string
}

const defaultProps = {}

export const Video: React.FC<VideoProps> = props => {
  if (!props.videoUrl) return null

  return (
    <div className="react-player">
      <ReactPlayer url={props.videoUrl} />
    </div>
  )
}

Video.defaultProps = defaultProps

export default Video

/******************************************************
	Data
******************************************************/
export const query = graphql`
  fragment VideoFields on ContentfulVideo {
    contentful_id
    id
    videoUrl
    caption
    internal {
      type
    }
  }
`
