import React from "react"

import Text from "../../components/UI/Text"

import { CopyModel } from "../../models/components"

export interface CardProps extends CopyModel {
  cardAlign?: string
  aos?: string
  aosDelay?: number | undefined
  aosAnchor?: string | undefined
}

const defaultProps = {
  aos: undefined,
  aosDelay: undefined,
  aosAnchor: undefined,
}

const Card: React.FC<CardProps> = props => {
  return (
    <div
      className={`card ${(props.cardAlign || "Center").toLowerCase()}`}
      data-aos={props.aos}
      data-aos-delay={props.aosDelay}
      data-aos-anchor={props.aosAnchor}
      data-aos-duration={600}
    >
      <Text content={props.content} options="card" />
    </div>
  )
}

Card.defaultProps = defaultProps

export default Card
