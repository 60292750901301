import * as React from "react"

import ReactPlayer from "react-player"

import { CmsAsset } from "../models/common"

export interface VideoFileProps extends CmsAsset {
  controls?: boolean
  className?: string
}

const defaultProps = {}

export const VideoFile: React.FC<VideoFileProps> = props => {
  if (!props.file || !props.file.url) return null

  const onLoadedData = () => {}

  return (
    <div className={`react-player-file ${props.className || ""}`}>
      <ReactPlayer
        url={props.file.url}
        width="100%"
        height="auto"
        playing={true}
        controls={props.controls}
        loop={true}
        muted={true}
        playsinline={true}
        onReady={onLoadedData}
      />
    </div>
  )
}

VideoFile.defaultProps = defaultProps

export default VideoFile
