import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { CmsImage } from "../models/common"

export interface ImageProps {
  image: CmsImage | undefined
  maxHeight?: number
  className?: string
}

const defaultProps = {
  maxHeight: undefined,
  className: "",
}

export const Image: React.FC<ImageProps> = props => {
  if (!props.image) return null

  // SVG
  if (props.image?.file?.contentType === "image/svg+xml") {
    let height = props.image?.file?.details?.image?.height || 0
    let width = props.image?.file?.details?.image?.width || 0

    if (props.maxHeight && height > props.maxHeight) {
      const scale = width / height
      height = props.maxHeight
      width = height * scale
    }

    return (
      <img
        src={props.image.file.url}
        width={width}
        height={height}
        alt={props.image.description}
        className={props.className}
      />
    )
  }

  // Image
  const image = getImage(props.image.gatsbyImageData)
  if (image) {
    return (
      <GatsbyImage
        image={image}
        alt={props.image.description}
        className={props.className}
      />
    )
  }

  return null
}

Image.defaultProps = defaultProps

export default Image
