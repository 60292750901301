import { createContext, useContext } from "react"

export type ModalContext = {
  modalName: string | null
  setModalName: (c: string) => void
}

export const AppModalContext = createContext<ModalContext>({
  modalName: null,
  setModalName: () => {},
})

export const useAppModalContext = () => useContext(AppModalContext)
