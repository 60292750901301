import React from "react"

import PhoneIcon from "../../images/svg/icon_phone.svg"
import MailIcon from "../../images/svg/icon_mail.svg"
import AgloLogo from "../../images/svg/aglo_logo-white.svg"

import Text from "../../components/UI/Text"
import Button from "../../components/Button"
import SocialList from "../../components/SocialList"
import MenuLink from "../../components/Menu/MenuLink"

import { MenuItemModel } from "../../models/menu"

import { useConfig } from "../../hooks/useConfig"
import { useAppModalContext } from "../../context/ModalContext"

interface FooterProps { }

const defaultProps = {}

const Footer: React.FC<FooterProps> = () => {
  const { footer, contact } = useConfig()

  const { modalName, setModalName } = useAppModalContext();
  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>  {url.indexOf("?footer=no") == -1 &&
      <>
        {modalName === "" && <footer className="Dark">
          <div className="grid-container">
            <div className="footer__content">
              <Text content={footer.footerContent} />
            </div>

            {/* <div className="footer__cta">
              <div className="embed-inline">
                <Button
                  url="/"
                  label="Book a Meeting"
                  style="Solid"
                  icon="Booking"
                  onClick={(ev) => { ev.preventDefault(); setModalName("book-meeting") }}
                />
              </div>
              <div className="embed-inline">
                <Button
                  url="/your-brief"
                  label="Submit your brief"
                  style="Text Link"
                  icon="Upload"
                  onClick={(ev) => { ev.preventDefault(); setModalName("your-brief") }}
                />
              </div>
            </div> */}

            <div className="grid-x grid-margin-x footer__bottom">
              <div className="cell large-shrink xxlarge-4 footer__contact">
                <div className="footer__phone">
                  {contact.phone && (
                    <>
                      <PhoneIcon fill="#34a853" />
                      <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                    </>
                  )}
                </div>

                <div className="footer__email">
                  {contact.email && (
                    <>
                      <MailIcon fill="#34a853" />
                      <a href={`mailto:${contact.email}`}>{contact.email}</a>
                    </>
                  )}
                </div>
              </div>
              <div className="cell large-shrink xxlarge-4 footer__social">
                <SocialList />
              </div>
              <div className="cell large-auto xxlarge-4 footer__other">
                <div className="footer__logo">
                  <AgloLogo />
                </div>

                <div className="footer__copyright">
                  &copy; {new Date().getFullYear()} Aglo
                </div>

                <ul className="footer__links">
                  {footer.footerMenu?.menuItems?.map((item: MenuItemModel) => (
                    <li key={item.id} className="footer__linkItem">
                      <MenuLink {...item} className="footer__link" />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </footer>
        }</>}</>
  )
}

Footer.defaultProps = defaultProps

export default Footer
