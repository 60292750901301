import React, { useState, useEffect } from "react"

import { Controller } from "react-hook-form"
import PhoneInput, {
  //isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input"

interface PhoneFieldProps {
  formFunctions: any
  name?: string
  label: string
  placeholder?: string
  required?: boolean
  hideLabel?: boolean
  handleOpenTip?: (value: string, offsetTop: number) => void | undefined
  hasTooltip?: boolean
}

const defaultProps = {
  name: undefined,
  required: false,
  hideLabel: false,
  handleOpenTip: undefined,
  hasTooltip: false,
}

const PhoneField: React.FC<PhoneFieldProps> = props => {
  // react-hook-form
  const control = props.formFunctions.control
  const errors = props.formFunctions.errors

  // state
  const [showValidation, setShowValidation] = useState(false)

  // effects
  useEffect(() => {
    if (!showValidation && Object.keys(errors).length) {
      setShowValidation(true)
    }
  }, [errors])

  // events
  const openTip = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const offsetTop =
      document.getElementById(input_id)?.getBoundingClientRect().top || 0
    if (props.handleOpenTip) props.handleOpenTip(input_name, offsetTop)
  }

  const input_name = props.name || props.label
  const input_id = input_name.split(" ").join("_")

  return (
    <div className="form-field">
      <label htmlFor={input_id}>
        {props.hideLabel ? (
          <span className="show-for-sr">{props.label}</span>
        ) : (
          <>{props.label}</>
        )}
      </label>
      <Controller
        control={control}
        name={input_name}
        render={({ field: { onChange, value, name } }) => (
          <div className="field__input">
            <PhoneInput
              id={input_id}
              name={name}
              value={value}
              onChange={onChange}
              international
              defaultCountry="AU"
              countryCallingCodeEditable={false}
            />
          </div>
        )}
        rules={{
          validate: value =>
            isValidPhoneNumber(value) || "Please enter a valid phone number",
          required: props.required ? `${props.label} is required` : false,
        }}
      />

      {errors.hasOwnProperty(input_name) && (
        <>
          <p className="error-msg">{errors[input_name].message}</p>
          <span className="field-error" />
        </>
      )}

      {showValidation && !errors.hasOwnProperty(input_name) && (
        <span className="field-valid" />
      )}

      {!showValidation && props.hasTooltip && (
        <button className="field-tip" onClick={openTip} />
      )}
    </div>
  )
}

PhoneField.defaultProps = defaultProps

export default PhoneField
