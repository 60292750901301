import React, { useState, useEffect } from "react"

import { Controller } from "react-hook-form"
import ReactDatePicker from "react-datepicker"

interface DateRangeFieldProps {
  formFunctions: any
  name?: string
  label: string
  startName?: string
  startLabel: string
  endName?: string
  endLabel: string
  placeholder?: string
  required?: boolean
  hideLabel?: boolean
  handleOpenTip?: (value: string, offsetTop: number) => void | undefined
  hasTooltip?: boolean
}

const defaultProps = {
  startName: undefined,
  endName: undefined,
  placeholder: "dd/mm/yy",
  required: false,
  hideLabel: false,
  handleOpenTip: undefined,
  hasTooltip: false,
}

const DateRangeField: React.FC<DateRangeFieldProps> = props => {
  // react-hook-form
  const control = props.formFunctions.control
  const errors = props.formFunctions.errors

  // state
  const [showValidation, setShowValidation] = useState(false)

  // effects
  useEffect(() => {
    if (!showValidation && Object.keys(errors).length) {
      setShowValidation(true)
    }
  }, [errors])

  // events
  const openTip = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const offsetTop =
      document.getElementById(startInput_id)?.getBoundingClientRect().top || 0
    if (props.handleOpenTip) props.handleOpenTip(startInput_name, offsetTop)
  }

  const startInput_name = props.startName || props.startLabel
  const startInput_id = startInput_name.split(" ").join("_")

  const endInput_name = props.endName || props.endLabel
  const endInput_id = endInput_name.split(" ").join("_")

  return (
    <div className="form-field">
      <label htmlFor={startInput_id}>
        {props.hideLabel ? (
          <span className="show-for-sr">{props.label}</span>
        ) : (
          <>{props.label}</>
        )}
      </label>
      <div className="field__input">
        <div className="grid-x align-middle">
          <div className="cell auto">
            <Controller
              control={control}
              name={startInput_name}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <ReactDatePicker
                  id={startInput_id}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  placeholderText={props.placeholder}
                  dateFormat={"dd/MM/yyyy"}
                />
              )}
              rules={{
                required: props.required
                  ? `${props.startLabel} is required`
                  : false,
              }}
            />
          </div>

          <div className="cell shrink">
            <span
              style={{ fontWeight: 800, color: "#34a853", padding: "0 10px" }}
            >
              ~
            </span>
          </div>

          <div className="cell auto">
            <Controller
              control={control}
              name={endInput_name}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <ReactDatePicker
                  id={endInput_id}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  placeholderText={props.placeholder}
                  dateFormat={"dd/MM/yyyy"}
                />
              )}
              rules={{
                required: props.required
                  ? `${props.endLabel} is required`
                  : false,
              }}
            />
          </div>
        </div>
      </div>

      {errors.hasOwnProperty(startInput_name) && (
        <p className="error-msg">{errors[startInput_name].message}</p>
      )}

      {errors.hasOwnProperty(endInput_name) && (
        <p className="error-msg">{errors[endInput_name].message}</p>
      )}

      {(errors.hasOwnProperty(startInput_name) ||
        errors.hasOwnProperty(endInput_name)) && (
        <span className="field-error" />
      )}

      {showValidation &&
        !errors.hasOwnProperty(startInput_name) &&
        !errors.hasOwnProperty(endInput_name) && (
          <span className="field-valid" />
        )}

      {!showValidation && props.hasTooltip && (
        <button className="field-tip" onClick={openTip} />
      )}
    </div>
  )
}

DateRangeField.defaultProps = defaultProps

export default DateRangeField
