import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import { CSSTransition } from "react-transition-group"

import MobMenu from "../Menu/MobMenu"
import DesktopMenu from "../Menu/DesktopMenu"

import LogoGreen from "../../images/svg/aglo_logo.svg"
import LogoWhite from "../../images/svg/aglo_logo-white.svg"
import MenuIcon from "../../images/svg/icon_menu.svg"

import useCurrentWidth from "../../hooks/useCurrentWidth"
import BasicDesktop from "../Menu/BasicDesktop"

interface HeaderProps {
  location: Location
  background?: string
  basic?: boolean
}

const defaultProps = {
  basic: false,
  background: "Light",
}

const Header: React.FC<HeaderProps> = props => {
  // hooks
  const screenWidth = useCurrentWidth()

  // state
  const [mobOpen, setMobOpen] = useState(false)

  // effects
  useEffect(() => {
    if (mobOpen && screenWidth > 1024) setMobOpen(false)
  }, [screenWidth])

  useEffect(() => {
    setTimeout(() => setMobOpen(false), 300)
  }, [props.location.pathname])

  // events
  const handleToggleMobMenu = () => {
    setTimeout(() => setMobOpen(!mobOpen), 200)
  }

  const iconColor =
    props.location.pathname === "/your-brief" ? "#fff" : "#1C1B20"
  const url = typeof window !== "undefined" ? window.location.href : ""
  return (
    <>
      <header>
        <div className="header__wrapper">
          <div className="grid-x grid-margin-x align-middle">
            <div className="cell shrink">
              {url.indexOf("?footer=no") == -1 && (
                <Link to="/" className="header__logo">
                  {props.background === "Dark" ? <LogoWhite /> : <LogoGreen />}
                </Link>
              )}
              {url.indexOf("?footer=no") != -1 && (
                <div className="header__logo">
                  {props.background === "Dark" ? <LogoWhite /> : <LogoGreen />}
                </div>
              )}
            </div>

            <div className="cell auto hide-for-large">
              <div className="header__mobActions">
                <button
                  className="header__menuBtn"
                  onClick={handleToggleMobMenu}
                >
                  <MenuIcon stroke={iconColor} fill={iconColor} />
                </button>
              </div>
            </div>

            <div className="cell auto show-for-large">
              {props.basic ? <BasicDesktop /> : <DesktopMenu />}
            </div>
          </div>
        </div>
      </header>

      <CSSTransition
        in={mobOpen}
        timeout={300}
        classNames="mobMenu"
        unmountOnExit
      >
        <MobMenu handleToggleMobMenu={handleToggleMobMenu} />
      </CSSTransition>
    </>
  )
}

Header.defaultProps = defaultProps

export default Header
