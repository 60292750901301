import React, { useState, useRef } from "react"

import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from "gatsby-source-contentful/rich-text"

import Modal from "react-modal"

import Text from "../../components/UI/Text"
import InputField from "../../components/Forms/InputField"
import SelectField from "../../components/Forms/SelectField"
import TextareaField from "../../components/Forms/TextareaField"
import InputRangeField from "../../components/Forms/InputRangeField"
import BriefStepButtons from "./BriefStepButtons"
import AttachFieldTips from "./AttachFieldTips"

import useFieldTips from "../../hooks/useFieldTips"

interface BriefAudienceProps {
  show: boolean
  content: RenderRichTextData<ContentfulRichTextGatsbyReference>
  formFunctions: any
  handleBackStep: () => void
  handleNextStep: () => void
}

const defaultProps = {}

const BriefAudience: React.FC<BriefAudienceProps> = props => {
  if (!props.show) return null

  // setup Modal
  Modal.setAppElement("#___gatsby")

  // hooks
  const { fieldTips, fieldTipTargets } = useFieldTips()

  // react-hook-form
  const trigger = props.formFunctions.trigger

  // state
  const [activeTip, setActiveTip] = useState("")
  const [tipOffset, setTipOffset] = useState(0)

  // refs
  const formRef = useRef<HTMLDivElement>(null)

  // events
  const nextStep = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const result = await trigger()
    if (result) props.handleNextStep()
  }

  const handleOpenTip = (value: string, offsetTop: number) => {
    const formOffset = formRef.current?.getBoundingClientRect().top || 0
    setTipOffset(offsetTop - formOffset)
    setActiveTip(value)
  }

  const handleCloseTip = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setActiveTip("")
  }

  // field names in this section
  const fieldNames = [
    "LEADCF2",
    "LEADCF51",
    "LEADCF13",
    "LEADCF12",
    "Description",
  ]

  // select options
  const gender_options = [
    { value: "All", label: "All" },
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ]

  return (
    <div className="grid-x grid-margin-x">
      <div className="cell large-6">
        <div className="brief__main" ref={formRef}>
          <div className="briefStep_content">
            <Text content={props.content} />
          </div>

          <div className="briefStep_form">
            <SelectField
              formFunctions={props.formFunctions}
              name="LEADCF2"
              label="Gender"
              options={gender_options}
              value={props.formFunctions.getValues("LEADCF2")}
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("LEADCF2") !== -1}
            />

            <InputRangeField
              formFunctions={props.formFunctions}
              type="number"
              min={13}
              label="Age Group"
              startName="LEADCF51"
              startLabel="Start Age Group"
              endName="LEADCF52"
              endLabel="End Age Group"
              startValue={props.formFunctions.getValues("LEADCF51")}
              endValue={props.formFunctions.getValues("LEADCF51")}
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("LEADCF51") !== -1}
            />

            <InputField
              formFunctions={props.formFunctions}
              name="LEADCF13"
              label="Target Country"
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("LEADCF13") !== -1}
            />

            <InputField
              formFunctions={props.formFunctions}
              name="LEADCF12"
              label="Target State"
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("LEADCF12") !== -1}
            />

            <TextareaField
              formFunctions={props.formFunctions}
              label="Description"
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("Description") !== -1}
            />
          </div>

          <BriefStepButtons
            handleBackStep={props.handleBackStep}
            handleNextStep={nextStep}
          />
        </div>
      </div>

      <div className="cell large-6">
        <div className="brief__sidebar">
          <AttachFieldTips
            fieldNames={fieldNames}
            fieldTips={fieldTips}
            activeTip={activeTip}
            tipOffset={tipOffset}
            handleCloseTip={handleCloseTip}
          />
        </div>
      </div>
    </div>
  )
}

BriefAudience.defaultProps = defaultProps

export default BriefAudience
