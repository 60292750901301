import React from "react"
import { graphql } from "gatsby"

import Contact from "./Contact/Contact"
import CaseStudyListing from "./CaseStudyListing/CaseStudyListing"

import { SpecialModel } from "../../models/blocks"

export interface SpecialProps extends SpecialModel {}

const defaultProps = {}

const Special: React.FC<SpecialProps> = props => {
  if (props.type === "Contact Form") return <Contact />
  if (props.type === "Case Study Listing") return <CaseStudyListing />

  return null
}

Special.defaultProps = defaultProps

export default Special

export const query = graphql`
  fragment SpecialFields on ContentfulBlockSpecial {
    contentful_id
    id
    type
    internal {
      type
    }
  }
`
