import React from "react"
import { graphql } from "gatsby"

import Text from "../../components/UI/Text"
import Button from "../../components/Button"
import Card from "./Card"

import { CardsModel } from "../../models/blocks"
import { CopyModel } from "../../models/components"

import * as utils from "../../utils"

export interface CardsProps extends CardsModel {}

const defaultProps = {
  cta: undefined,
}

const Cards: React.FC<CardsProps> = props => {
  let layoutClasses = "medium-up-2 large-up-3"
  if (props.cards.length === 4) layoutClasses = "medium-up-2 xlarge-up-4"

  const aosClass = `aos-${Math.floor(Math.random() * 100000)}`

  return (
    <section className={`cards`}>
      <div className={`grid-container ${aosClass}`}>
        {props.content && (
          <Text
            content={props.content}
            className="cards__content"
            aos="fade-right"
            aosAnchor={`.${aosClass}`}
          />
        )}

        <div className={`cards__inner`}>
          <div className={`grid-x grid-margin-x ${layoutClasses} align-spaced`}>
            {props.cards?.map((card: CopyModel, index: number) => (
              <div key={`${card.id}_${index}`} className="cell card-wrapper">
                <Card
                  {...card}
                  cardAlign={props.cardAlign}
                  aos="zoom-in"
                  aosDelay={
                    200 * index + (props.content ? 200 : 0) + utils.aosBaseDelay
                  }
                  aosAnchor={`.${aosClass}`}
                />
              </div>
            ))}
          </div>
        </div>

        {props.cta && (
          <div
            className="cards__cta"
            data-aos="fade-up"
            data-aos-delay={
              200 * props.cards.length +
              (props.content ? 200 : 0) +
              utils.aosBaseDelay
            }
            data-aos-anchor={`.${aosClass}`}
          >
            <Button {...props.cta} />
          </div>
        )}
      </div>
    </section>
  )
}

Cards.defaultProps = defaultProps

export default Cards

export const query = graphql`
  fragment CardsFields on ContentfulBlockCards {
    contentful_id
    id
    content {
      raw
      references {
        ...RichTextCopyFields
      }
    }
    cardAlign
    cards {
      ...CopyFields
    }
    cta {
      ...ButtonFields
    }
    internal {
      type
    }
  }
`
