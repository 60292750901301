import React, { useEffect, useState } from "react"

import TagList from "../../../components/TagList"

import { CaseStudyModel } from "../../../models/pages"

interface CaseStudyFilterProps {
  caseStudies: CaseStudyModel[]
  tagParam: string | null
  setTagParam: any
  setPage: any
  aos?: string | undefined
  aosDelay?: number | undefined
  aosAnchor?: string | undefined
}

const defaultProps = {
  aos: undefined,
  aosDelay: undefined,
  aosAnchor: undefined,
}

const CaseStudyFilter: React.FC<CaseStudyFilterProps> = props => {
  // state
  const [filterList, setFilterList] = useState<string[]>([])

  // effects
  useEffect(() => {
    if (props.caseStudies) {
      let list = props.caseStudies
        .map((x: CaseStudyModel) => x.tags)
        .flat()
        .sort()

      const uniq: string[] = [...new Set(list)]
      setFilterList(uniq)
    }
  }, [props.caseStudies])

  // events
  const handleTagSelect = (value: string) => {
    props.setTagParam(value === props.tagParam ? undefined : value)
    props.setPage(0)
  }

  return (
    <div className="caseStudyFilter">
      <div className="grid-x grid-margin-x">
        <div className="cell large-auto large-text-right">
          <span className="filter__heading">Filter:</span>
        </div>
        <div className="cell large-shrink">
          <TagList
            tags={filterList}
            activeTag={props.tagParam}
            handleTagSelect={handleTagSelect}
            //aos={hasAnimated ? undefined : props.aos}
            //aosDelay={props.aosDelay}
            //aosAnchor={props.aosAnchor}
          />
        </div>
      </div>
    </div>
  )
}

CaseStudyFilter.defaultProps = defaultProps

export default CaseStudyFilter
