import React from "react"
import { graphql, Link } from "gatsby"

import * as utils from "../utils"

import { useAppModalContext } from "../context/ModalContext"

import { ButtonModel } from "../models/components"

export interface ButtonProps extends ButtonModel {
  className?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

const defaultProps = {
  entry: undefined,
  asset: undefined,
  url: undefined,
  style: undefined,
  icon: undefined,
  className: "",
  onClick: () => {},
}

const Button: React.FC<ButtonProps> = props => {
  const { setModalName } = useAppModalContext()

  // get the CTA link data
  const link = utils.getCtaLink(props.url, props.entry, props.asset)

  // button styling
  let styleClass: string = ""
  if (props.style === "Solid") styleClass = "button"
  if (props.style === "Alt Solid") styleClass = "button alt"
  if (props.style === "Transparent") styleClass = "button transparent"
  if (props.style === "Text Link") styleClass = "button text"

  // button icon
  let iconStyle: string = ""
  if (props.icon === "Booking") iconStyle = "icon--booking"
  if (props.icon === "Upload") iconStyle = "icon--upload"

  if (props.label && link.url) {
    if (link.isExternal) {
      return (
        <a
          href={link.url}
          className={`${styleClass} ${iconStyle} ${props.className || ""}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="button__text">{props.label}</span>
        </a>
      )
    } else {
      return (
        <Link
          to={link.url}
          className={`${styleClass} ${iconStyle} ${props.className || ""}`}
          onClick={e => {
            setModalName("")
            if (props.onClick) props.onClick(e)
          }}
        >
          <span className="button__text">{props.label}</span>
        </Link>
      )
    }
  }

  return null
}

Button.defaultProps = defaultProps

export default Button

export const query = graphql`
  fragment ButtonFields on ContentfulButton {
    contentful_id
    id
    style
    label
    icon
    url
    entry {
      ...ContentfulPageLinkFields
    }
    asset {
      ...ContentfulAssetFields
    }
    internal {
      type
    }
  }
`
