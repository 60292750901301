import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"

import { useForm } from "react-hook-form"
import { format } from "date-fns"
import SimpleBar from "simplebar-react"

import axios from "axios"

import BriefIntro from "./BriefIntro"
import FullForm from "./FullForm"
import ShortForm from "./ShortForm"

import { BriefFormModel } from "../../models/blocks"

export interface YourBriefProps extends BriefFormModel {
  isShortForm: boolean
}

const defaultProps = {
  isShortForm: false,
}

const YourBrief: React.FC<YourBriefProps> = props => {
  // hooks
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    formState,
    control,
  } = useForm()

  // refs
  const scrollRef = useRef<HTMLElement>(null)

  // state
  const [isShortForm, setIsShortForm] = useState(false)
  const [step, setStep] = useState(0)
  const [formFunctions, setFormFunctions] = useState({
    register: register,
    handleSubmit: handleSubmit,
    errors: formState.errors,
    trigger: trigger,
    getValues: getValues,
    setValue: setValue,
    control: control,
  })

  useEffect(() => {
    setFormFunctions({
      register: register,
      handleSubmit: handleSubmit,
      errors: formState.errors,
      trigger: trigger,
      getValues: getValues,
      setValue: setValue,
      control: control,
    })
  }, [
    formState.errors,
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    control,
  ])

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.scrollTo({ top: 0, behavior: "smooth" })
    }
  }, [step, scrollRef])

  // events
  const handleBackStep = () => {
    setStep(step - 1)
  }

  const handleNextStep = () => {
    let stepNum = step + 1
    if (stepNum >= 7) {
      briefSubmit(getValues())
    } else {
      setStep(stepNum)
    }
  }
  const handleShortForm = () => {
    setIsShortForm(true)
    setStep(1)
  }

  // steps
  const fullSteps = [
    "Intro",
    "Contact",
    "Business",
    "Campaign",
    "Audience",
    "Messaging",
    "Assets",
    "Finish",
  ]

  const shortSteps = ["Intro", "Contact", "Assets", "Finish"]

  const steps = isShortForm ? shortSteps : fullSteps

  const briefSubmit = async (formData: any) => {
    formData["xnQsjsdp"] =
      "98412710d97fec545507911f75f0ba34b49dbf75678da60fb3f76a9ec0f4816b"
    formData["zc_gad"] = ""
    formData["xmIwtLD"] =
      "45ceea99544a99abab034339ff80f155a8c9e3abdc578599e8d6beb193a140aa"
    formData["actionType"] = "TGVhZHM="
    formData["returnURL"] = "https://www.aglo.co/thanks"
    formData["LEADCF16"] = formData["BrandAssets"]
    formData["LEADCF15"] = formData["CreativeAssets"]

    // format Dates
    formData["LEADCF81"] = formData["LEADCF81"]
      ? format(formData["LEADCF81"], "MMM d, yyyy")
      : undefined
    formData["LEADCF82"] = formData["LEADCF82"]
      ? format(formData["LEADCF82"], "MMM d, yyyy")
      : undefined

    delete formData["BrandAssets"]
    delete formData["CreativeAssets"]

    let response = await axios({
      url: `/api/brief`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    })

    if (response?.data?.success) setStep(7)
    else console.log(response.data)
  }

  return (
    <section className="yourBrief">
      <div className="grid-container">
        {step !== steps.length - 1 && (
          <ul className="formSteps show-for-medium">
            {steps.map((label: string, i: number) => (
              <li
                key={`stepMarker_${i}`}
                className={`step__item ${step >= i ? "active" : ""}`}
              >
                {label}
              </li>
            ))}
          </ul>
        )}

        <SimpleBar
          style={{ maxHeight: "calc(100vh - 82px - 40px - 50px - 85px)" }}
          scrollableNodeProps={{ ref: scrollRef }}
        >
          <div className="grid-container">
            <BriefIntro
              intro_1={props.intro_1}
              intro_2={props.intro_2}
              time={props.time}
              questionCount={props.questionCount}
              show={step === 0}
              handleNextStep={handleNextStep}
              handleShortForm={handleShortForm}
              setIsShortForm={setIsShortForm}
            />

            <form className="" onSubmit={handleSubmit(briefSubmit)}>
              {isShortForm && (
                <ShortForm
                  {...props}
                  step={step}
                  formFunctions={formFunctions}
                  handleBackStep={handleBackStep}
                  handleNextStep={handleNextStep}
                />
              )}

              {!isShortForm && (
                <FullForm
                  {...props}
                  step={step}
                  formFunctions={formFunctions}
                  handleBackStep={handleBackStep}
                  handleNextStep={handleNextStep}
                />
              )}
            </form>
          </div>
        </SimpleBar>
      </div>
    </section>
  )
}

YourBrief.defaultProps = defaultProps

export default YourBrief

export const query = graphql`
  fragment BriefFormFields on ContentfulBlockBriefForm {
    contentful_id
    id
    time
    questionCount
    intro_1 {
      raw
      references {
        ...RichTextCopyFields
      }
    }
    intro_2 {
      raw
      references {
        ...RichTextCopyFields
      }
    }
    stepContent_contact {
      raw
      references {
        ...RichTextCopyFields
      }
    }
    stepContent_business {
      raw
      references {
        ...RichTextCopyFields
      }
    }
    stepContent_campaign {
      raw
      references {
        ...RichTextCopyFields
      }
    }
    stepContent_audience {
      raw
      references {
        ...RichTextCopyFields
      }
    }
    stepContent_messaging {
      raw
      references {
        ...RichTextCopyFields
      }
    }
    stepContent_brandAssets {
      raw
      references {
        ...RichTextCopyFields
      }
    }
    stepContent_creativeAssets {
      raw
      references {
        ...RichTextCopyFields
      }
    }
    finish {
      raw
      references {
        ...RichTextCopyFields
      }
    }
    internal {
      type
    }
  }
`
