import React, { useState, useRef } from "react"

import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from "gatsby-source-contentful/rich-text"

import Modal from "react-modal"

import Text from "../../components/UI/Text"
import SelectField from "../../components/Forms/SelectField"
import DateRangeField from "../../components/Forms/DateRangeField"
import BriefStepButtons from "./BriefStepButtons"
import AttachFieldTips from "./AttachFieldTips"

import useFieldTips from "../../hooks/useFieldTips"

interface BriefCampaignProps {
  show: boolean
  content: RenderRichTextData<ContentfulRichTextGatsbyReference>
  formFunctions: any
  handleBackStep: () => void
  handleNextStep: () => void
}

const defaultProps = {}

const BriefCampaign: React.FC<BriefCampaignProps> = props => {
  if (!props.show) return null

  // setup Modal
  Modal.setAppElement("#___gatsby")

  // hooks
  const { fieldTips, fieldTipTargets } = useFieldTips()

  // react-hook-form
  const trigger = props.formFunctions.trigger

  // state
  const [activeTip, setActiveTip] = useState("")
  const [tipOffset, setTipOffset] = useState(0)

  // refs
  const formRef = useRef<HTMLDivElement>(null)

  // events
  const nextStep = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const result = await trigger()
    if (result) props.handleNextStep()
  }

  const handleOpenTip = (value: string, offsetTop: number) => {
    const formOffset = formRef.current?.getBoundingClientRect().top || 0
    setTipOffset(offsetTop - formOffset)
    setActiveTip(value)
  }

  const handleCloseTip = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setActiveTip("")
  }

  // field names in this section
  const fieldNames = ["LEADCF5", "LEADCF82", "LEADCF4", "LEADCF3"]

  // select options
  const mediaBudget_options = [
    { value: "None", label: "-None-" },
    { value: "< $5,000", label: "< $5,000" },
    { value: "$5-$510k", label: "$5-$10k" },
    { value: "$10-$20k", label: "$10-$20k" },
    { value: "$20-$40k", label: "$20-$40k" },
    { value: ">$40k+", label: "> $40k" },
  ]

  const coreObjective_options = [
    { value: "None", label: "-None-" },
    { value: "Awareness", label: "Awareness" },
    { value: "Consideration", label: "Consideration" },
    { value: "Recall", label: "Recall" },
    { value: "Leads", label: "Leads" },
    { value: "Conversions", label: "Conversions" },
    { value: "Uptake", label: "Uptake" },
  ]

  const measurement_options = [
    { value: "None", label: "-None-" },
    { value: "Gain awareness", label: "Gain awareness" },
    { value: "See the ad", label: "See the ad" },
    { value: "Watch a video", label: "Watch a video" },
    { value: "Interact with our asset", label: "Interact with our asset" },
    { value: "Like our Page", label: "Like our Page" },
    { value: "Attend our event", label: "Attend our event" },
    { value: "Download or View our App", label: "Download or View our App" },
    { value: "Give us their details", label: "Give us their details" },
    {
      value: "Take an action on my website",
      label: "Take an action on my website",
    },
    { value: "Visit my store", label: "Visit my store" },
  ]

  return (
    <div className="grid-x grid-margin-x">
      <div className="cell large-6">
        <div className="brief__main" ref={formRef}>
          <div className="briefStep_content">
            <Text content={props.content} />
          </div>

          <div className="briefStep_form">
            <SelectField
              formFunctions={props.formFunctions}
              name="LEADCF5"
              label="Media Budget"
              options={mediaBudget_options}
              value={props.formFunctions.getValues("LEADCF5")}
              required
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("LEADCF5") !== -1}
            />

            <DateRangeField
              formFunctions={props.formFunctions}
              label="Campaign Date"
              startName="LEADCF82"
              startLabel="Campaign Start Date"
              endName="LEADCF81"
              endLabel="Campaign End Date"
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("LEADCF82") !== -1}
            />

            <SelectField
              formFunctions={props.formFunctions}
              name="LEADCF4"
              label="Core Objective"
              options={coreObjective_options}
              handleOpenTip={handleOpenTip}
              value={props.formFunctions.getValues("LEADCF4")}
              hasTooltip={fieldTipTargets.indexOf("LEADCF4") !== -1}
            />

            <SelectField
              formFunctions={props.formFunctions}
              name="LEADCF3"
              label="Measurement Metric"
              options={measurement_options}
              handleOpenTip={handleOpenTip}
              value={props.formFunctions.getValues("LEADCF3")}
              hasTooltip={fieldTipTargets.indexOf("LEADCF3") !== -1}
            />
          </div>

          <BriefStepButtons
            handleBackStep={props.handleBackStep}
            handleNextStep={nextStep}
          />
        </div>
      </div>

      <div className="cell large-6">
        <div className="brief__sidebar">
          <AttachFieldTips
            fieldNames={fieldNames}
            fieldTips={fieldTips}
            activeTip={activeTip}
            tipOffset={tipOffset}
            handleCloseTip={handleCloseTip}
          />
        </div>
      </div>
    </div>
  )
}

BriefCampaign.defaultProps = defaultProps

export default BriefCampaign
