import React, { useState, useRef } from "react"

import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from "gatsby-source-contentful/rich-text"

import Modal from "react-modal"

import Text from "../../components/UI/Text"
import InputField from "../../components/Forms/InputField"
import BriefStepButtons from "./BriefStepButtons"
import AttachFieldTips from "./AttachFieldTips"

import useFieldTips from "../../hooks/useFieldTips"

interface BriefBusinessProps {
  show: boolean
  content: RenderRichTextData<ContentfulRichTextGatsbyReference>
  formFunctions: any
  handleBackStep: () => void
  handleNextStep: () => void
}

const defaultProps = {}

const BriefBusiness: React.FC<BriefBusinessProps> = props => {
  if (!props.show) return null

  // setup Modal
  Modal.setAppElement("#___gatsby")

  // hooks
  const { fieldTips, fieldTipTargets } = useFieldTips()

  // react-hook-form
  const trigger = props.formFunctions.trigger

  // state
  const [activeTip, setActiveTip] = useState("")
  const [tipOffset, setTipOffset] = useState(0)

  // refs
  const formRef = useRef<HTMLDivElement>(null)

  // events
  const nextStep = async (e: React.MouseEvent<HTMLButtonElement>) => {
    props.formFunctions.setValue("uuid", new Date().getTime())
    e.preventDefault()
    const result = await trigger()
    if (result) props.handleNextStep()
  }

  const handleOpenTip = (value: string, offsetTop: number) => {
    const formOffset = formRef.current?.getBoundingClientRect().top || 0
    setTipOffset(offsetTop - formOffset)
    setActiveTip(value)
  }

  const handleCloseTip = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setActiveTip("")
  }

  // field names in this section
  const fieldNames = [
    "Company",
    "LEADCF1",
    "Street",
    "City",
    "State",
    "Zip Code",
    "Country",
  ]

  return (
    <div className="grid-x grid-margin-x">
      <div className="cell large-6">
        <div className="brief__main" ref={formRef}>
          <div className="briefStep_content">
            <Text content={props.content} />
          </div>

          <div className="briefStep_form">
            <InputField
              formFunctions={props.formFunctions}
              label="Company"
              required
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("Company") !== -1}
            />

            <InputField
              formFunctions={props.formFunctions}
              name="LEADCF1"
              label="Job Title"
              required
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("LEADCF1") !== -1}
            />

            <InputField
              formFunctions={props.formFunctions}
              label="Street"
              required
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("Street") !== -1}
            />

            <InputField
              formFunctions={props.formFunctions}
              label="City"
              required
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("City") !== -1}
            />

            <InputField
              formFunctions={props.formFunctions}
              label="State"
              required
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("State") !== -1}
            />

            <InputField
              formFunctions={props.formFunctions}
              label="Zip Code"
              required
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("Zip Code") !== -1}
            />

            <InputField
              formFunctions={props.formFunctions}
              label="Country"
              required
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("Country") !== -1}
            />
          </div>

          <BriefStepButtons
            handleBackStep={props.handleBackStep}
            handleNextStep={nextStep}
          />
        </div>
      </div>

      <div className="cell large-6">
        <div className="brief__sidebar">
          <AttachFieldTips
            fieldNames={fieldNames}
            fieldTips={fieldTips}
            activeTip={activeTip}
            tipOffset={tipOffset}
            handleCloseTip={handleCloseTip}
          />
        </div>
      </div>
    </div>
  )
}

BriefBusiness.defaultProps = defaultProps

export default BriefBusiness
