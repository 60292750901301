import React from "react"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"
import axios from "axios"

import InputField from "../../../components/Forms/InputField"
import PhoneField from "../../../components/Forms/PhoneField"
import TextareaField from "../../../components/Forms/TextareaField"

import Text from "../../../components/UI/Text"
import Button from "../../../components/Button"
import SocialList from "../../../components/SocialList"

import PhoneIcon from "../../../images/svg/icon_phone.svg"
import MailIcon from "../../../images/svg/icon_mail.svg"

import { useConfig } from "../../../hooks/useConfig"

import { useAppModalContext } from "../../../context/ModalContext"

export interface ContactProps {}

const defaultProps = {}

const Contact: React.FC<ContactProps> = () => {
  const { setModalName } = useAppModalContext()

  // hooks
  const { contact } = useConfig()
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    formState,
    control,
  } = useForm()

  const formFunctions = {
    register: register,
    handleSubmit: handleSubmit,
    errors: formState.errors,
    trigger: trigger,
    getValues: getValues,
    setValue: setValue,
    control: control,
  }
  const contactSubmit = async (formData: any) => {
    //("formData: ", formData)
    formData["xnQsjsdp"] =
      "98412710d97fec545507911f75f0ba34b49dbf75678da60fb3f76a9ec0f4816b"
    formData["zc_gad"] = ""
    formData["xmIwtLD"] =
      "45ceea99544a99abab034339ff80f155fb05c04df5a9cc04a3f4c36ba3c23c10"
    formData["actionType"] = "TGVhZHM="

    formData["returnURL"] = "https://www.aglo.co/thanks"

    let response = await axios({
      url: `/api/brief`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    })

    if (response?.data?.success) {
      navigate("/thanks")
    } else {
      console.log(response.data)
    }
  }

  return (
    <section className="contact">
      <div className="grid-container">
        <div className="contact__inner">
          <div className="grid-x grid-margin-x  align-spaced">
            <div className="cell medium-shrink contact__bottom ">
              <h1>
                We're in
                <br />
                Sydney
                <br />
                Say Hello
              </h1>
            </div>

            <div className="cell medium-shrink">
              <div
                className="hide-for-small-only"
                style={{ paddingTop: "165px" }}
              ></div>
              {contact.phone && (
                <a href={`tel:${contact.phone}`} style={{ marginLeft: 14 }}>
                  {contact.phone}
                </a>
              )}
              <br />
              {contact.email && (
                <a href={`mailto:${contact.email}`} style={{ marginLeft: 14 }}>
                  {contact.email}
                </a>
              )}
              <br />
              &nbsp; <SocialList color="#34A853" style={{ display: "inline"}} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

Contact.defaultProps = defaultProps

export default Contact
