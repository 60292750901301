import React, { useState, useEffect } from "react"

import Helmet from "react-helmet"
import AOS from "aos"

import Transition from "../components/Layout/Transition"
import Header from "../components/Layout/Header"
import Footer from "../components/Layout/Footer"

import { AppModalContext } from "../context/ModalContext"
import YourBriefModal from "../components/Modals/YourBriefModal"
import BookMeeting from "../components/Modals/BookMeetingModal"

import * as utils from "../utils"

interface Props {
  children: React.ReactNode
  location: Location
  pageContext?: any
}

const defaultProps = {
  pageContext: {},
}

const Layout = ({ children, location, pageContext }: Props) => {
  const [modalName, setModalName] = useState("")

  useEffect(() => {
    AOS.init({
      //offset: 200,
      duration: 1000,
      delay: utils.aosBaseDelay,
      anchorPlacement: "top-bottom",
      once: true,
    })
  }, [])

  return (
    <>
      <AppModalContext.Provider value={{ modalName, setModalName }}>
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/zwe6yuj.css" />
        </Helmet>

        <Header
          location={location}
          basic={pageContext.basicHeader}
          background={pageContext.background}
        />

        <main>
          <Transition location={location}>{children}</Transition>
        </main>

        <Footer />
        <YourBriefModal />
        <BookMeeting />
      </AppModalContext.Provider>
    </>
  )
}

Layout.defaultProps = defaultProps

export default Layout
