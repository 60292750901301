import React, { useState, useEffect } from "react"

interface TextareaFieldProps {
  formFunctions: any
  name?: string
  label: string
  rows?: number
  placeholder?: string
  required?: boolean
  hideLabel?: boolean
  handleOpenTip?: (value: string, offsetTop: number) => void | undefined
  hasTooltip?: boolean
}

const defaultProps = {
  name: undefined,
  rows: 2,
  placeholder: "",
  required: false,
  hideLabel: false,
  handleOpenTip: undefined,
  hasTooltip: false,
}

const TextareaField: React.FC<TextareaFieldProps> = props => {
  // react-hook-form
  const register = props.formFunctions.register
  const errors = props.formFunctions.errors

  // state
  const [showValidation, setShowValidation] = useState(false)

  // effects
  useEffect(() => {
    if (!showValidation && Object.keys(errors).length) {
      setShowValidation(true)
    }
  }, [errors])

  // events
  const openTip = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const offsetTop =
      document.getElementById(input_id)?.getBoundingClientRect().top || 0
    if (props.handleOpenTip) props.handleOpenTip(input_name, offsetTop)
  }

  const input_name = props.name || props.label
  const input_id = input_name.split(" ").join("_")

  return (
    <div className="form-field">
      <label htmlFor={input_id}>
        {props.hideLabel ? (
          <span className="show-for-sr">{props.label}</span>
        ) : (
          <>{props.label}</>
        )}
      </label>
      <div className="field__input" style={{ verticalAlign: "top" }}>
        <textarea
          id={input_id}
          rows={props.rows}
          placeholder={props.placeholder}
          className={errors && errors[input_name] && "error"}
          {...register(input_name, {
            required: props.required ? `${props.label} is required` : false,
          })}
        />
      </div>

      {errors.hasOwnProperty(input_name) && (
        <>
          <p className="error-msg">{errors[input_name].message}</p>
          <span className="field-error" />
        </>
      )}

      {showValidation && !errors.hasOwnProperty(input_name) && (
        <span className="field-valid" />
      )}

      {!showValidation && props.hasTooltip && (
        <button className="field-tip" onClick={openTip} />
      )}
    </div>
  )
}

TextareaField.defaultProps = defaultProps

export default TextareaField
