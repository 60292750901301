import React from "react"
import { graphql } from "gatsby"

import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

import Text from "../../components/UI/Text"

import { ProcessRouteModel, FeatureModel } from "../../models/blocks"

//import * as utils from "../../utils"

export interface ProcessRouteProps extends ProcessRouteModel {}

const defaultProps = {}

const ProcessRoute: React.FC<ProcessRouteProps> = props => {
  if (!props.steps) return null

  return (
    <section className={`processRoute`}>
      <div className="grid-container">
        {props.heading && <h2>{props.heading}</h2>}

        <div className="route__wrapper">
          <StaticImage
            src="../../images/process_route-mobile.png"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="a line joining the 4 steps of what to expect"
            className="route__image--mobile"
            placeholder="none"
          />

          <StaticImage
            src="../../images/process_route-desktop.png"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="a line joining the 4 steps of what to expect"
            className="route__image--desktop"
            placeholder="none"
          />

          {props.steps.map((step: FeatureModel, index: number) => {
            const image = step.image
              ? getImage(step.image.gatsbyImageData)
              : undefined

            const aosClass = `aos-${Math.floor(Math.random() * 100000)}`

            return (
              <div
                key={step.id}
                className={`route__step step--${index + 1} ${aosClass}`}
              >
                <div
                  className="route__stepNumber h3"
                  //data-aos={index % 2 == 0 ? "fade-left" : "fade-right"}
                  data-aos="fade-down"
                  data-aos-delay={0}
                  data-aos-anchor={`.${aosClass}`}
                >
                  {index + 1}
                </div>
                <div
                  className="route__stepImage"
                  data-aos={index % 2 == 0 ? "fade-right" : "fade-left"}
                  data-aos-delay={0}
                  data-aos-anchor={`.${aosClass}`}
                >
                  {image && (
                    <GatsbyImage
                      image={image}
                      alt={step.image?.description || ""}
                      className=""
                      objectFit="contain"
                    />
                  )}
                </div>
                <Text
                  content={step.content}
                  className="route__stepContent"
                  aos="fade-up"
                  aosDelay={0}
                  aosAnchor={`.${aosClass}`}
                />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

ProcessRoute.defaultProps = defaultProps

export default ProcessRoute

export const query = graphql`
  fragment ProcessRouteFields on ContentfulBlockProcessRoute {
    contentful_id
    id
    heading
    steps {
      ...FeatureFields
    }
    internal {
      type
    }
  }
`
