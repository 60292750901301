import React, { useState, useEffect } from "react"

interface InputFieldProps {
  formFunctions: any
  type?: string
  name?: string
  label: string
  placeholder?: string
  required?: boolean
  min?: number
  max?: number
  hideLabel?: boolean
  handleOpenTip?: (value: string, offsetTop: number) => void | undefined
  hasTooltip?: boolean
}

const defaultProps = {
  type: "text",
  name: undefined,
  placeholder: "",
  required: false,
  min: undefined,
  max: undefined,
  hideLabel: false,
  handleOpenTip: undefined,
  hasTooltip: false,
}

const InputField: React.FC<InputFieldProps> = props => {
  // react-hook-form
  const register = props.formFunctions.register
  const errors = props.formFunctions.errors

  // state
  const [showValidation, setShowValidation] = useState(false)

  // effects
  useEffect(() => {
    if (!showValidation && Object.keys(errors).length) {
      setShowValidation(true)
    }
  }, [errors])

  // events
  const openTip = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const offsetTop =
      document.getElementById(input_id)?.getBoundingClientRect().top || 0
    if (props.handleOpenTip) props.handleOpenTip(input_name, offsetTop)
  }

  const input_name = props.name || props.label
  const input_id = input_name.split(" ").join("_")

  return (
    <div className="form-field">
      <label htmlFor={input_id}>
        {props.hideLabel ? (
          <span className="show-for-sr">{props.label}</span>
        ) : (
          <>{props.label}</>
        )}
      </label>
      <div className="field__input">
        <input
          id={input_id}
          type={props.type}
          placeholder={props.placeholder}
          className={errors && errors[input_name] && "error"}
          {...register(input_name, {
            required: props.required ? `${props.label} is required` : false,
            min: props.min ? props.min : undefined,
            max: props.max ? props.max : undefined,
            pattern: getPattern(props.type),
          })}
        />
      </div>

      {errors.hasOwnProperty(input_name) && (
        <>
          <p className="error-msg">{errors[input_name].message}</p>
          <span className="field-error" />
        </>
      )}

      {showValidation && !errors.hasOwnProperty(input_name) && (
        <span className="field-valid" />
      )}

      {!showValidation && props.hasTooltip && (
        <button className="field-tip" onClick={openTip} />
      )}
    </div>
  )
}

InputField.defaultProps = defaultProps

export default InputField

const getPattern = (type: string | undefined) => {
  if (type === "email") {
    return {
      value:
        /(?:[A-Za-z0-9!#$%&amp;&#039;*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&amp;&#039;*+/=?^_`{|}~-]+)*|&quot;(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*&quot;)@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
      message: "Please enter a valid email",
    }
  }

  return null
}
