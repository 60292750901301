import React from "react"
import { graphql } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { GalleryModel } from "../../models/blocks"
import { CmsImage } from "../../models/common"

import * as utils from "../../utils"

export interface GalleryProps extends GalleryModel {}

const defaultProps = {}

const Gallery: React.FC<GalleryProps> = props => {
  let gridClasses = `small-up-${props.smallRow}`
  if (props.mediumRow) gridClasses += ` medium-up-${props.mediumRow}`
  if (props.largeRow) gridClasses += ` large-up-${props.largeRow}`
  if (props.xLargeRow) gridClasses += ` xlarge-up-${props.xLargeRow}`
  if (props.xxLargeRow) gridClasses += ` xxlarge-up-${props.xxLargeRow}`

  const aosClass = `aos-${Math.floor(Math.random() * 100000)}`

  return (
    <section className={`gallery`}>
      <div className={`grid-container ${aosClass}`}>
        <div
          className={`grid-x grid-margin-x ${gridClasses} align-middle align-center`}
        >
          {props.images.map((image: CmsImage, index: number) => {
            const imageData = image
              ? getImage(image.gatsbyImageData)
              : undefined

            if (!imageData) return null

            return (
              <div
                key={image.id}
                className="cell"
                data-aos="zoom-out"
                data-aos-delay={150 * index + utils.aosBaseDelay}
                data-aos-anchor={`.${aosClass}`}
              >
                <GatsbyImage
                  image={imageData}
                  alt={image?.description || ""}
                  className="gallery__image"
                  objectFit="contain"
                />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

Gallery.defaultProps = defaultProps

export default Gallery

export const query = graphql`
  fragment GalleryFields on ContentfulBlockGallery {
    contentful_id
    id
    smallRow
    mediumRow
    largeRow
    xLargeRow
    xxLargeRow
    images {
      ...MediaImageFields
      gatsbyImageData(placeholder: BLURRED, quality: 95)
    }
    internal {
      type
    }
  }
`
