import React from "react"
import { graphql } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Text from "../../components/UI/Text"

import { CalloutModel } from "../../models/blocks"
import VideoFile from "../../components/VideoFile"

export interface CalloutProps extends CalloutModel {}

const defaultProps = {
  image: undefined,
  tag: undefined,
}

const Callout: React.FC<CalloutProps> = props => {
  const imgSrc = props.imageAlign === "Top" ? props.banner : props.sideImg

  const image = imgSrc ? getImage(imgSrc) : undefined
  const hasVideo = props.image?.file?.url?.indexOf("mp4") !== -1

  const aosClass = `aos-${Math.floor(Math.random() * 100000)}`

  return (
    <section className={`callout ${props.imageAlign}`}>
      <div className={`grid-container ${aosClass}`}>
        <div
          className={`grid-x grid-margin-x ${
            props.imageVertical === "Middle" ? "align-middle" : ""
          }`}
        >
          <div
            className={`cell ${
              props.imageAlign === "Top" ? "" : "large-shrink"
            } ${props.imageAlign === "Right" ? "large-order-2" : ""}`}
          >
            <div
              className="callout__image"
              data-aos={
                props.imageAlign === "Right"
                  ? "fade-left"
                  : props.imageAlign === "Left"
                  ? "fade-right"
                  : "fade-down"
              }
              data-aos-anchor={`.${aosClass}`}
            >
              {props.image && (
                <>
                  {hasVideo && <VideoFile {...props.image} />}
                  {!hasVideo && image && (
                    <GatsbyImage
                      image={image}
                      alt={props.image?.description || ""}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div
            className={`cell ${props.imageAlign === "Top" ? "" : "large-auto"}`}
          >
            {props.tag && <div className="h3">{props.tag}</div>}
            <Text
              content={props.content}
              className="callout__content"
              aos="fade-up"
              aosAnchor={`.${aosClass}`}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

Callout.defaultProps = defaultProps

export default Callout

export const query = graphql`
  fragment CalloutFields on ContentfulBlockCalloutTile {
    contentful_id
    id
    image {
      ...MediaImageFields
    }
    sideImg: image {
      gatsbyImageData(height: 410, placeholder: BLURRED, quality: 95)
    }
    banner: image {
      gatsbyImageData(width: 1000, placeholder: BLURRED, quality: 95)
    }
    imageAlign
    imageVertical
    tag
    content {
      raw
      references {
        ...RichTextContentFields
      }
    }
    internal {
      type
    }
  }
`
