import React from "react"

import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from "gatsby-source-contentful/rich-text"

import Text from "../../components/UI/Text"

import CrossIcon from "../../images/svg/icon_cross.svg"

interface BriefStep_7Props {
  name?: string
  active?: boolean
  activeTip?: string
  tipOffset?: number
  content?: RenderRichTextData<ContentfulRichTextGatsbyReference> | string
  handleCloseTip: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
}

const defaultProps = {
  className: "",
}

const FieldTip: React.FC<BriefStep_7Props> = props => {
  if (!props.active) return null

  return (
    <div
      className={`fieldTip ${props.className}`}
      style={{ top: props.tipOffset }}
    >
      <div className="fieldTip__inner">
        <Text content={props.content} className="fieldTip__content" />

        <button className="fieldTip__close" onClick={props.handleCloseTip}>
          <span className="show-for-sr">close tip</span>
          <CrossIcon fill="#fff" />
        </button>
      </div>
    </div>
  )
}

FieldTip.defaultProps = defaultProps

export default FieldTip
