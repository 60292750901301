import React from "react"
import { Link } from "gatsby"

import * as utils from "../../utils"
import { MenuItemModel } from "../../models/menu"

export interface MenuLinkProps extends MenuItemModel {
  className?: string
}

const defaultProps = {
  className: "",
}

const MenuLink: React.FC<MenuLinkProps> = props => {
  // get the link data
  const link = utils.getCtaLink(props.url, props.entry, null)

  if (link.url) {
    return (
      <>
        {link.isExternal ? (
          <a
            href={link.url}
            className={props.className}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.label}
          </a>
        ) : (
          <Link to={link.url} className={props.className}>
            {props.label}
          </Link>
        )}
      </>
    )
  }

  return null
}

MenuLink.defaultProps = defaultProps

export default MenuLink
