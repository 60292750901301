/******************************************************
	
  Asset Rendering
  
******************************************************/
import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"

import Image from "../../components/Image"
import VideoFile from "../../components/VideoFile"

interface AssetProps {
  maxHeight?: number
}

const defaultProps = {
  maxHeight: undefined,
}

export const render_assets = (props: AssetProps) => {
  return {
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      //console.log("EMBEDDED_ASSET: ", node)
      const nodeData = node.data ? node.data.target : null

      const isImage =
        nodeData.file.contentType?.indexOf("image") !== -1 ||
        nodeData.file.contentType?.indexOf("svg") !== -1

      if (isImage)
        return <Image image={nodeData} {...props} className={`image-embed`} />

      if (nodeData.file.contentType?.indexOf("video") !== -1)
        return <VideoFile {...nodeData} className={`image-embed`} />

      return null
    },
  }
}

render_assets.defaultProps = defaultProps

export default render_assets
