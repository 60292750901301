import React from "react"

import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from "gatsby-source-contentful/rich-text"

import Text from "../../components/UI/Text"
import { StaticImage } from "gatsby-plugin-image"

interface BriefFinishProps {
  show: boolean
  content: RenderRichTextData<ContentfulRichTextGatsbyReference>
}

const defaultProps = {}

const BriefFinish: React.FC<BriefFinishProps> = props => {
  if (!props.show) return null

  return (
    <div className="grid-x grid-margin-x brief__thanks">
      <div className="cell large-6">
        <div className="brief__main">
          <div className="briefStep_content">
            <Text content={props.content} />
          </div>
        </div>
      </div>
      <div className="cell large-6">
        <div className="brief__thanksImage">
          <StaticImage
            src="../../images/aglo_semiCircles_-_right.png"
            height={445}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Semi-circle rings in a green gradient"
            className="hero__image"
            placeholder="none"
          />
        </div>
      </div>
    </div>
  )
}

BriefFinish.defaultProps = defaultProps

export default BriefFinish
