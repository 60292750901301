import React from "react"
import { graphql } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Text from "../../components/UI/Text"

import { FeatureModel } from "../../models/blocks"

export interface FeatureProps extends FeatureModel {}

const defaultProps = {
  image: undefined,
}

const FeatureTile: React.FC<FeatureProps> = props => {
  const image = props.image ? getImage(props.image.gatsbyImageData) : undefined

  return (
    <section className="featureTile">
      <div className="grid-container">
        <div className="featureTile__inner">
          <div className="grid-x grid-margin-x align-middle">
            <div className="cell medium-shrink">
              <div className="featureTile__image">
                {image && (
                  <GatsbyImage
                    image={image}
                    alt={props.image?.description || ""}
                    className=""
                    objectFit="contain"
                  />
                )}
              </div>
            </div>
            <div className="cell medium-auto">
              <div className="featureTile__content">
                <Text content={props.content} options="card" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

FeatureTile.defaultProps = defaultProps

export default FeatureTile

export const query = graphql`
  fragment FeatureFields on ContentfulBlockFeatureTile {
    contentful_id
    id
    image {
      ...MediaImageFields
      gatsbyImageData(
        width: 150
        height: 150
        placeholder: BLURRED
        quality: 95
      )
    }
    content {
      raw
      references {
        ...RichTextCopyFields
      }
    }
    internal {
      type
    }
  }
`
