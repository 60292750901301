import React, { useEffect } from "react"

import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from "gatsby-source-contentful/rich-text"

import ClockIcon from "../../images/svg/icon_clock.svg"
import QuestionIcon from "../../images/svg/icon_question.svg"

import Text from "../../components/UI/Text"

interface BriefIntroProps {
  intro_1: RenderRichTextData<ContentfulRichTextGatsbyReference>
  intro_2?: RenderRichTextData<ContentfulRichTextGatsbyReference>
  time?: string
  questionCount?: number
  show: boolean
  handleNextStep: () => void
  handleShortForm: () => void
  setIsShortForm: (x: boolean) => void
}

const defaultProps = {
  content_2: undefined,
  time: undefined,
  questionCount: undefined,
}

const BriefIntro: React.FC<BriefIntroProps> = props => {
  if (!props.show) return null

  useEffect(() => {
    props.setIsShortForm(false)
  }, [])

  return (
    <div className="grid-x grid-margin-x">
      <div className="cell large-6">
        <div className="brief__main">
          <div className="briefStep__content">
            <Text content={props.intro_1} />
          </div>

          <div className="briefStep__stats">
            <h6>
              <strong>What to expect</strong>
            </h6>
            <div className="grid-x grid-margin-x">
              {props.time && (
                <div className="cell medium-6 large-12 xlarge-6 briefStep__Stat">
                  <ClockIcon fill="#34a853" /> {props.time}
                </div>
              )}

              {props.questionCount && (
                <div className="cell medium-6 large-12 xlarge-6 briefStep__Stat">
                  <QuestionIcon fill="#34a853" /> {props.questionCount}{" "}
                  questions
                </div>
              )}
            </div>
          </div>

          <div className="briefStep__content">
            <Text content={props.intro_2} />
            <button
              className="briefStep__setShortForm"
              onClick={props.handleShortForm}
            >
              Upload prepared brief here.
            </button>
          </div>

          <div className="briefStep__cta">
            <button className="button" onClick={props.handleNextStep}>
              Let's get started
            </button>
          </div>
        </div>
      </div>
      <div className="cell large-6">
        <div className="brief__sidebar"></div>
      </div>
    </div>
  )
}

BriefIntro.defaultProps = defaultProps

export default BriefIntro
