/******************************************************
	
  Card - Rich Text Render Options
  
******************************************************/
import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"

import render_hyperLinks from "./render_hyperLinks"
import render_assets from "./render_assets"
import render_newline from "./render_newline"

import Button from "../../components/Button"

export const renderOptions = {
  renderNode: {
    // common hyperlink rendering
    ...render_hyperLinks,

    // common asset rendering
    ...render_assets({ maxHeight: 200 }),

    [BLOCKS.HEADING_1]: (_node: any, children: any) => {
      return <h3>{children}</h3>
    },

    [BLOCKS.HEADING_2]: (_node: any, children: any) => {
      return <h3>{children}</h3>
    },

    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      //console.log("EMBEDDED_ENTRY: ", node)
      const nodeData = node.data ? node.data.target : null
      if (nodeData) {
        const entryType = nodeData?.internal?.type || null

        if (entryType === "ContentfulButton") {
          return <Button {...nodeData} />
        }
      }

      return null
    },
  },

  renderMark: {},

  renderText: (text: any) => {
    return render_newline(text)
  },
}

export default renderOptions
