import React from "react"

import Facebook from "../images/svg/icon_Facebook.svg"
import Instagram from "../images/svg/icon_Instagram.svg"
import Twitter from "../images/svg/icon_Twitter.svg"
import LinkedIn from "../images/svg/icon_LinkedIn.svg"
import YouTube from "../images/svg/icon_YouTube.svg"

import { useConfig } from "../hooks/useConfig"

interface SocialListProps {
  color?: string
  style?:any
}

const defaultProps = {
  color: "#fff",
  style:{}
}

const SocialList: React.FC<SocialListProps> = props => {
  const { social } = useConfig()

  return (
    <ul className="socialList" style={props.style}>
      {social.facebook && (
        <li className="socialList__item">
          <a href={social.facebook} className="socialList__link">
            <Facebook fill={props.color} />
          </a>
        </li>
      )}

      {social.instagram && (
        <li className="socialList__item">
          <a href={social.instagram} className="socialList__link">
            <Instagram fill={props.color} />
          </a>
        </li>
      )}

      {social.twitter && (
        <li className="socialList__item">
          <a href={social.twitter} className="socialList__link">
            <Twitter fill={props.color} />
          </a>
        </li>
      )}

      {social.linkedIn && (
        <li className="socialList__item">
          <a href={social.linkedIn} className="socialList__link">
            <LinkedIn fill={props.color} />
          </a>
        </li>
      )}

      {social.youTube && (
        <li className="socialList__item">
          <a href={social.youTube} className="socialList__link">
            <YouTube fill={props.color} />
          </a>
        </li>
      )}
    </ul>
  )
}

SocialList.defaultProps = defaultProps

export default SocialList
