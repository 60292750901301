import React from "react"

import BriefContact from "./BriefContact"
import BriefBusiness from "./BriefBusiness"
import BriefCampaign from "./BriefCampaign"
import BriefAudience from "./BriefAudience"
import BriefMessaging from "./BriefMessaging"
import BriefAssets from "./BriefAssets"
import BriefFinish from "./BriefFinish"

import { BriefFormModel } from "../../models/blocks"

export interface FullFormProps extends BriefFormModel {
  step: number
  formFunctions: any
  handleBackStep: () => void
  handleNextStep: () => void
}

const defaultProps = {}

const FullForm: React.FC<FullFormProps> = props => {
  return (
    <>
      <BriefContact
        show={props.step === 1}
        content={props.stepContent_contact}
        formFunctions={props.formFunctions}
        handleBackStep={props.handleBackStep}
        handleNextStep={props.handleNextStep}
      />

      <BriefBusiness
        show={props.step === 2}
        content={props.stepContent_business}
        formFunctions={props.formFunctions}
        handleBackStep={props.handleBackStep}
        handleNextStep={props.handleNextStep}
      />

      <BriefCampaign
        show={props.step === 3}
        content={props.stepContent_campaign}
        formFunctions={props.formFunctions}
        handleBackStep={props.handleBackStep}
        handleNextStep={props.handleNextStep}
      />

      <BriefAudience
        show={props.step === 4}
        content={props.stepContent_audience}
        formFunctions={props.formFunctions}
        handleBackStep={props.handleBackStep}
        handleNextStep={props.handleNextStep}
      />

      <BriefMessaging
        show={props.step === 5}
        content={props.stepContent_messaging}
        formFunctions={props.formFunctions}
        handleBackStep={props.handleBackStep}
        handleNextStep={props.handleNextStep}
      />

      <BriefAssets
        show={props.step === 6}
        brandAssets={props.stepContent_brandAssets}
        creativeAssets={props.stepContent_creativeAssets}
        formFunctions={props.formFunctions}
        handleBackStep={props.handleBackStep}
        handleNextStep={props.handleNextStep}
      />

      <BriefFinish show={props.step === 7} content={props.finish} />
    </>
  )
}

FullForm.defaultProps = defaultProps

export default FullForm
