import React from "react"

export interface NoResultsProps {}

const defaultProps = {}

export const NoResults: React.FC<NoResultsProps> = () => {
  return (
    <section className="listing__noResults">
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell">
            <div className="h3">No results</div>
          </div>
        </div>
      </div>
    </section>
  )
}

NoResults.defaultProps = defaultProps

export default NoResults
