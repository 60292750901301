/******************************************************
	
  Body - Rich Text Render Options
  
******************************************************/
import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"

import render_hyperLinks from "./render_hyperLinks"
import render_assets from "./render_assets"
import render_newline from "./render_newline"

import Callout from "../../blocks/Callout/Callout"
import Cards from "../../blocks/Cards/Cards"
import CaseStudyListing from "../../blocks/CaseStudyListing/CaseStudyListing"
import Content from "../../blocks/Content/Content"
import FeatureTile from "../../blocks/FeatureTile/FeatureTile"
import Gallery from "../../blocks/Gallery/Gallery"
import ProcessRoute from "../../blocks/ProcessRoute/ProcessRoute"
import Slider from "../../blocks/Slider/Slider"
import Special from "../../blocks/Special/Special"
import YourBrief from "../../blocks/YourBrief/YourBrief"

import Button from "../../components/Button"
import Video from "../../components/Video"

export const renderOptions = {
  renderNode: {
    // common hyperlink rendering
    ...render_hyperLinks,

    // common asset rendering
    ...render_assets({ maxHeight: 200 }),

    [BLOCKS.HEADING_1]: (_node: any, children: any) => {
      return <h2 className="h1">{children}</h2>
    },

    [BLOCKS.PARAGRAPH]: (_node: any, children: any) => {
      //console.log("_node: ", _node)
      // don't show empty <p> tags
      if (_node.content[0].value === "") return null
      return <p>{children}</p>
    },

    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      //console.log("EMBEDDED_ENTRY: ", node)
      const nodeData = node.data ? node.data.target : null
      if (nodeData) {
        const entryType = nodeData?.internal?.type || null

        if (entryType === "ContentfulBlockCalloutTile") {
          return <Callout {...nodeData} />
        }

        if (entryType === "ContentfulBlockCards") {
          return <Cards {...nodeData} />
        }

        if (entryType === "ContentfulBlockCaseStudyListing") {
          return <CaseStudyListing {...nodeData} />
        }

        if (entryType === "ContentfulBlockContent") {
          return <Content {...nodeData} />
        }

        if (entryType === "ContentfulBlockFeatureTile") {
          return <FeatureTile {...nodeData} />
        }

        if (entryType === "ContentfulBlockGallery") {
          return <Gallery {...nodeData} />
        }

        if (entryType === "ContentfulBlockProcessRoute") {
          return <ProcessRoute {...nodeData} />
        }

        if (entryType === "ContentfulBlockSlider") {
          return <Slider {...nodeData} />
        }

        if (entryType === "ContentfulBlockSpecial") {
          return <Special {...nodeData} />
        }

        if (entryType === "ContentfulBlockBriefForm") {
          return <YourBrief {...nodeData} />
        }

        if (entryType === "ContentfulButton") {
          return <Button {...nodeData} />
        }

        if (entryType === "ContentfulVideo") {
          return <Video {...nodeData} />
        }

        if (entryType === "ContentfulBackgroundColor") {
          return (
            <div
              className={`bgColor bgColor--${nodeData.backgroundColor?.toLowerCase()}`}
            />
          )
        }
      }

      return null
    },
  },

  renderMark: {},

  renderText: (text: any) => {
    return render_newline(text)
  },
}

export default renderOptions
