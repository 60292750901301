import React from "react"

import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

import Text from "../../components/UI/Text"

import { CalloutModel } from "../../models/blocks"

export interface SlideProps extends CalloutModel {
  next: () => void
  previous: () => void
  aos?: string
  aosDelay?: number | undefined
  aosAnchor?: string | undefined
  index: number
}

const defaultProps = {
  image: undefined,
  tag: undefined,
  next: () => {},
  previous: () => {},
  aos: undefined,
  aosDelay: undefined,
  aosAnchor: undefined,
}

const Slide: React.FC<SlideProps> = props => {
  const image = props.sideImg ? getImage(props.sideImg) : undefined

  return (
    <div className="callout Right">
      <div className="grid-container">
        <div className="grid-x grid-margin-x ">
          {props.tag && (
            <div className="cell hide-for-large">
              <div className="callout__tag h3">{props.tag}</div>
            </div>
          )}
          <div
            className="cell large-shrink large-order-2"
            data-aos={props.index === 0 ? "fade-left" : undefined}
            data-aos-delay={props.index === 0 ? props.aosDelay : undefined}
            data-aos-anchor={props.index === 0 ? props.aosAnchor : undefined}
          >
            <BgImage image={image}>
              <div className="callout__image" />
            </BgImage>
          </div>
          <div className="cell large-auto" style={{ position: "relative" }}>
            {props.tag && (
              <div
                className="callout__tag h3 show-for-large"
                data-aos={props.index === 0 ? "fade-right" : undefined}
                data-aos-delay={props.index === 0 ? props.aosDelay : undefined}
                data-aos-anchor={
                  props.index === 0 ? props.aosAnchor : undefined
                }
              >
                {props.tag}
              </div>
            )}
            <Text
              content={props.content}
              className="callout__content"
              aos={props.index === 0 ? "fade-up" : undefined}
              aosDelay={props.index === 0 ? props.aosDelay : undefined}
              aosAnchor={props.index === 0 ? props.aosAnchor : undefined}
            />
            <button
              type="button"
              data-role="none"
              className="slick-arrow slick-prev hide-for-large"
              onClick={props.previous}
            >
              Previous
            </button>
            <button
              type="button"
              data-role="none"
              className="slick-arrow slick-next hide-for-large"
              onClick={props.next}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

Slide.defaultProps = defaultProps

export default Slide
