import React, { useState, useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useQueryParam, StringParam, NumberParam } from "use-query-params"

import CaseStudyFilter from "./CaseStudyFilter"
import CaseStudyCard from "./CaseStudyCard"

import { CaseStudyModel } from "../../../models/pages"

import * as utils from "../../../utils"

interface CaseStudyListingProps {}

const defaultProps = {}

const itemsPerPage = 4

const CaseStudyListing: React.FC<CaseStudyListingProps> = () => {
  const queryData = useStaticQuery(graphql`
    query {
      pages: allContentfulPageCaseStudy(
        filter: { entryName: { ne: "do not delete" } }
        sort: { fields: heading, order: ASC }
      ) {
        nodes {
          ...CaseStudyCardFields
        }
      }
    }
  `)

  // query string
  const [tagParam = "", setTagParam] = useQueryParam("tags", StringParam)
  const [page = 0, setPage] = useQueryParam("page", NumberParam)

  // state
  const [caseStudies] = useState(queryData.pages.nodes)
  const [filteredList, setFilteredList] = useState([])
  const [listHeight, setListHeight] = useState(0)
  const [exit, setExit] = useState(false)

  // refs
  const wrapperRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setExit(true)

    setTimeout(() => {
      setFilteredList([])
      setExit(false)

      setTimeout(() => {
        setFilteredList(
          tagParam
            ? caseStudies.filter((x: CaseStudyModel) =>
                x.tags.includes(tagParam)
              )
            : caseStudies
        )
      }, 0)
    }, 300)

    if (page !== undefined) setPage(undefined)
  }, [tagParam])

  useEffect(() => {
    setListHeight(listRef?.current?.clientHeight || 0)
  }, [filteredList, page])

  // events
  const loadMore = () => {
    setPage((page || 0) + 1)
  }

  const showMoreBtn = itemsPerPage * ((page || 0) + 1) < filteredList.length
  const aosClass = `aos-${Math.floor(Math.random() * 100000)}`

  return (
    <section className={`caseStudyListing`}>
      <div className={`grid-container ${aosClass}`}>
        <CaseStudyFilter
          caseStudies={caseStudies}
          tagParam={tagParam}
          setTagParam={setTagParam}
          setPage={setPage}
          aos={"fade-up"}
          aosDelay={utils.aosBaseDelay + 600}
          aosAnchor={`.${aosClass}`}
        />

        <div
          className={`listing__wrapper ${exit ? "exit" : ""}`}
          style={{
            height: listHeight,
          }}
          ref={wrapperRef}
        >
          <div
            className="grid-x grid-margin-x medium-up-2 large-up-2"
            ref={listRef}
          >
            {filteredList
              .slice(0, ((page || 0) + 1) * itemsPerPage)
              .map((caseStudy: CaseStudyModel, index: number) => (
                <CaseStudyCard
                  key={caseStudy.id}
                  {...caseStudy}
                  className="cell"
                  setTagParam={setTagParam}
                  aos={index % 2 === 0 ? "fade-right" : "fade-left"}
                  aosDelay={(index - (page || 0) * itemsPerPage) * 200 + 400}
                  aosAnchor={`.${aosClass}`}
                />
              ))}
          </div>
        </div>

        <div
          className="listing__cta"
          style={{
            visibility: showMoreBtn ? "visible" : "hidden",
            opacity: showMoreBtn ? 1 : 0,
          }}
        >
          <button className="button transparent" onClick={loadMore}>
            Load More
          </button>
        </div>
      </div>
    </section>
  )
}

CaseStudyListing.defaultProps = defaultProps

export default CaseStudyListing
