/******************************************************
	
  New Line Rendering
  
******************************************************/
import React from "react"

export const render_newline = (text: any) => {
  return text
    .split("\n")
    .reduce((children: any, textSegment: any, index: number) => {
      return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
}

export default render_newline
