/****************************************************

	Utilities - Constants

****************************************************/

/****************************************************
  AOS Animation
****************************************************/
export const aosBaseDelay = 400

/****************************************************
  React-Select styles
****************************************************/
export const customSelectStyles = {
  container: (styles: any) => ({
    ...styles,
    fontSize: 16,
    lineHeight: "29px",
  }),
  control: (styles: any) => ({
    ...styles,
    border: "none",
    borderRadius: "none",
    ":hover": { cursor: "pointer" },
  }),
  valueContainer: (styles: any) => ({ ...styles, padding: "10px 22px 11px" }),
  placeholder: (styles: any) => ({ ...styles, color: "#1c1b20", margin: 0 }),
  input: (styles: any) => ({ ...styles, padding: 0, margin: 0 }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
  menu: (styles: any) => ({
    ...styles,
    fontSize: 16,
    lineHeight: "29px",
    color: "#1c1b20",
    marginTop: 0,
    borderRadius: "none",
  }),
  option: (styles: any) => ({ ...styles, paddingLeft: 22, cursor: "pointer" }),
}
