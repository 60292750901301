import React from "react"

import MenuLink from "./MenuLink"

import useConfig from "../../hooks/useConfig"

import { MenuItemModel } from "../../models/menu"

interface DesktopMenuProps {}

const DesktopMenu: React.FC<DesktopMenuProps> = () => {
  // hooks
  const { mainMenu } = useConfig()

  return (
    <div className="grid-x align-middle align-right header__deskMenu">
      {mainMenu?.menuItems?.map((menuItem: MenuItemModel) => (
        <div key={menuItem.id} className="cell shrink">
          <MenuLink {...menuItem} className="header__menuLink" />
        </div>
      ))}
    </div>
  )
}

export default DesktopMenu
