import { graphql, useStaticQuery } from "gatsby"

import { ToolTipModel } from "../models/components"

export const useFieldTips = () => {
  const queryData = useStaticQuery(graphql`
    query {
      items: allContentfulTooltip(
        filter: { entryName: { ne: "do not delete" } }
      ) {
        nodes {
          contentful_id
          id
          target
          content {
            raw
            references {
              ... on Node {
                ...RichTextContentFields
              }
            }
          }
        }
      }
    }
  `)

  return {
    fieldTips: queryData.items.nodes,
    fieldTipTargets: queryData.items.nodes.map((x: ToolTipModel) => x.target),
  }
}

export default useFieldTips
