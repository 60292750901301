/******************************************************
	
  HyperLink Rendering
  
******************************************************/
import React from "react"
import { Link } from "gatsby"
import { INLINES } from "@contentful/rich-text-types"

import { getPageSlugWithPrefix, getCtaLink } from "../utils_links"

export const render_hyperLinks = {
  [INLINES.HYPERLINK]: (node: any, children: any) => {
    //console.log("HYPERLINK: ", node)
    const link = getCtaLink(node.data.uri, null, null)

    if (link.isExternal)
      return (
        <a href={link.url} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      )

    return <Link to={link.url}>{children}</Link>
  },

  [INLINES.ENTRY_HYPERLINK]: (node: any, children: any) => {
    //console.log("ENTRY_HYPERLINK: ", node)
    const nodeData = node.data ? node.data.target : null

    if (nodeData) {
      return <Link to={getPageSlugWithPrefix(nodeData)}>{children}</Link>
    }

    return null
  },

  [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
    //console.log("ASSET_HYPERLINK: ", node)
    const nodeData = node.data ? node.data.target : null

    if (nodeData && nodeData.file) {
      return (
        <a href={nodeData.file.url} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      )
    }

    return null
  },
}

export default render_hyperLinks
