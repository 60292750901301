import React from "react"

import {
  renderRichText,
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from "gatsby-source-contentful/rich-text"

import content_RenderOptions from "../../utils/renderOptions/content_richText"
import body_RenderOptions from "../../utils/renderOptions/body_richText"
import card_RenderOptions from "../../utils/renderOptions/card_richText"
// import { banner_RT_RenderOptions } from "../../utils/renderOptions/banner_richText"
// import { plain_RT_RenderOptions } from "../../utils/renderOptions/plainText_richText"

export interface TextProps {
  content?: RenderRichTextData<ContentfulRichTextGatsbyReference> | string
  options?: "body" | "content" | "card" | "plainText"
  className?: string
  aos?: string
  aosDelay?: number
  aosAnchor?: string
  aosAnchorPlacement?: string
}

const defaultProps = {
  content: undefined,
  options: undefined,
  className: "",
  aos: undefined,
  aosDelay: undefined,
  aosAnchor: undefined,
  aosAnchorPlacement: undefined,
}

export const Text: React.FC<TextProps> = props => {
  if (!props.content && !props.children) return null

  // handle html being passed as children instead of a rich text object
  if (props.children)
    return <div className={props.className}>{props.children}</div>

  // handle a string being passed instead of a rich text object
  if (typeof props.content === "string")
    return <div className={props.className}>{props.content}</div>

  let renderOptions: any = content_RenderOptions
  if (props.options === "body") renderOptions = body_RenderOptions
  if (props.options === "card") renderOptions = card_RenderOptions
  //   if (props.options === "plainText") renderOptions = plain_RT_RenderOptions

  if (props.content)
    return (
      <div
        className={props.className}
        data-aos={props.aos}
        data-aos-delay={props.aosDelay}
        data-aos-anchor={props.aosAnchor}
        data-aos-anchor-placement={props.aosAnchorPlacement}
      >
        {renderRichText(props.content, renderOptions)}
      </div>
    )

  return null
}

Text.defaultProps = defaultProps

export default Text
