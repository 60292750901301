import React from "react"

import TagList from "../../components/TagList"

interface CaseStudyFilterProps {
  tags: string[]
  tagParam: string | null
  setTagParam: any
  setPage: any
}

const defaultProps = {}

const CaseStudyFilter: React.FC<CaseStudyFilterProps> = props => {
  // events
  const handleTagSelect = (value: string) => {
    props.setTagParam(value === props.tagParam ? undefined : value)
    props.setPage(0)
  }

  return (
    <div className="caseStudyFilter">
      <div className="grid-x grid-margin-x">
        <div className="cell large-auto large-text-right">
          <span className="filter__heading">Filter:</span>
        </div>
        <div className="cell large-shrink">
          <TagList
            tags={props.tags || []}
            activeTag={props.tagParam}
            handleTagSelect={handleTagSelect}
          />
        </div>
      </div>
    </div>
  )
}

CaseStudyFilter.defaultProps = defaultProps

export default CaseStudyFilter
