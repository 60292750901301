import React from "react"

import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"

// This variable will be responsible for our animation duration
const timeout = 400

// This object contains basic styles for animation, but you can extend them to whatever you like. Be creative!
const getTransitionStyles: any = {
  entering: {
    position: `absolute`,
    opacity: 0,
    transform: `translateY(40px)`,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out, transform ${
      timeout + 400
    }ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out, transform ${
      timeout + 400
    }ms ease-in-out`,
    opacity: 0,
  },
}

class Transition extends React.PureComponent<
  { children: React.ReactNode; location: Location },
  {}
> {
  render() {
    // Destructuring props to avoid garbage this.props... in return statement
    const { children, location } = this.props

    return (
      // Using TransitionGroup and ReactTransition which are both
      // coming from  'react-transition-group' and are required for transitions to work
      <TransitionGroup>
        <ReactTransition
          // The key is necessary here because our ReactTransition needs to know when pages are entering/exiting the DOM
          key={location.pathname}
          // Duration of transition
          timeout={{
            enter: timeout,
            exit: timeout,
          }}
        >
          {
            // Application of the styles depending on the status of page(entering, exiting, entered) in the DOM
            status => (
              <div
                style={{
                  ...getTransitionStyles[status],
                }}
              >
                {children}
              </div>
            )
          }
        </ReactTransition>
      </TransitionGroup>
    )
  }
}

export default Transition
