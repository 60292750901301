import React from "react"
import { graphql } from "gatsby"

import Text from "../../components/UI/Text"

import { ContentModel } from "../../models/blocks"

export interface ContentProps extends ContentModel {}

const defaultProps = {}

const Content: React.FC<ContentProps> = props => {
  let alignClasses = `text-${props.mobAlign.toLowerCase()}`
  if (props.deskAlign)
    alignClasses += ` large-text-${props.deskAlign.toLowerCase()}`

  return (
    <section className="content">
      <div className={`grid-container ${alignClasses}`}>
        <div className="content__content">
          <Text content={props.content} />
        </div>
      </div>
    </section>
  )
}

Content.defaultProps = defaultProps

export default Content

export const query = graphql`
  fragment ContentFields on ContentfulBlockContent {
    contentful_id
    id
    mobAlign
    deskAlign
    content {
      raw
      references {
        ...RichTextContentFields
      }
    }
    internal {
      type
    }
  }
`
