import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import { useQueryParam, StringParam, NumberParam } from "use-query-params"
import useInfiniteScroll from "react-infinite-scroll-hook"

import CaseStudyFilter from "./CaseStudyFilter"
import CaseStudyCard from "./CaseStudyCard"
import NoResults from "./NoResults"

import { CaseStudyModel } from "../../models/pages"
import { CaseStudyListingModel } from "../../models/blocks"

interface CaseStudyListingProps extends CaseStudyListingModel {}

const defaultProps = {
  caseStudies: [],
}

const itemsPerPage = 2

const CaseStudyListing: React.FC<CaseStudyListingProps> = props => {
  // query string
  const [tagParam = "", setTagParam] = useQueryParam("tags", StringParam)
  const [page = 1, setPage] = useQueryParam("page", NumberParam)

  // variables
  const caseStudies = props.caseStudies || []

  // state
  const [filteredList, setFilteredList] =
    useState<CaseStudyModel[]>(caseStudies)
  const [loading, setLoading] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [error, setError] = useState(false)

  // effects
  useEffect(() => {
    setHasNextPage(itemsPerPage * ((page || 0) + 1) < filteredList.length)
    setLoading(false)
  }, [page])

  useEffect(() => {
    if (tagParam) {
      setFilteredList(
        caseStudies.filter(page => (page.tags || []).indexOf(tagParam) !== -1)
      )
    } else {
      setFilteredList(caseStudies)
    }
    setPage(1)
  }, [tagParam])

  // events
  const loadMore = () => {
    setLoading(true)
    setPage((page || 1) + 1)
  }

  // infinite scroll
  const [loadRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    //rootMargin: "0px 0px 400px 0px",
  })

  const aosClass = `aos-${Math.floor(Math.random() * 100000)}`

  return (
    <section className={`caseStudyListing`}>
      <div className={`grid-container ${aosClass}`}>
        {props.featureTags && (
          <CaseStudyFilter
            tags={props.featureTags}
            tagParam={tagParam}
            setTagParam={setTagParam}
            setPage={setPage}
          />
        )}

        {filteredList.length === 0 && <NoResults />}

        {filteredList.length > 0 && (
          <div className="listing__wrapper">
            <div className="grid-x grid-margin-x medium-up-2 large-up-2">
              {filteredList
                .slice(0, ((page || 0) + 1) * itemsPerPage)
                .map((caseStudy: CaseStudyModel, index: number) => (
                  <CaseStudyCard
                    key={caseStudy.id}
                    {...caseStudy}
                    className="cell"
                    setTagParam={setTagParam}
                    aos={index % 2 === 0 ? "fade-right" : "fade-left"}
                    aosDelay={(index - (page || 1) * itemsPerPage) * 200 + 400}
                    aosAnchor={`.${aosClass}`}
                  />
                ))}
            </div>
            {(loading || hasNextPage) && <div ref={loadRef} />}
          </div>
        )}
      </div>
    </section>
  )
}

CaseStudyListing.defaultProps = defaultProps

export default CaseStudyListing

export const query = graphql`
  fragment CaseStudyListingFields on ContentfulBlockCaseStudyListing {
    contentful_id
    id
    featureTags
    caseStudies {
      ...CaseStudyCardFields
    }
    internal {
      type
    }
  }
`
