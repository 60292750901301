import React, { useState, useRef } from "react"

import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from "gatsby-source-contentful/rich-text"

import Modal from "react-modal"

import Text from "../../components/UI/Text"
import InputField from "../../components/Forms/InputField"
import TextareaField from "../../components/Forms/TextareaField"
import BriefStepButtons from "./BriefStepButtons"
import AttachFieldTips from "./AttachFieldTips"

import useFieldTips from "../../hooks/useFieldTips"

interface BriefMessagingProps {
  show: boolean
  content: RenderRichTextData<ContentfulRichTextGatsbyReference>
  formFunctions: any
  handleBackStep: () => void
  handleNextStep: () => void
}

const defaultProps = {}

const BriefMessaging: React.FC<BriefMessagingProps> = props => {
  if (!props.show) return null

  // setup Modal
  Modal.setAppElement("#___gatsby")

  // hooks
  const { fieldTips, fieldTipTargets } = useFieldTips()

  // react-hook-form
  const trigger = props.formFunctions.trigger

  // state
  const [activeTip, setActiveTip] = useState("")
  const [tipOffset, setTipOffset] = useState(0)

  // refs
  const formRef = useRef<HTMLDivElement>(null)

  // events
  const nextStep = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const result = await trigger()
    if (result) props.handleNextStep()
  }

  const handleOpenTip = (value: string, offsetTop: number) => {
    const formOffset = formRef.current?.getBoundingClientRect().top || 0
    setTipOffset(offsetTop - formOffset)
    setActiveTip(value)
  }

  const handleCloseTip = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setActiveTip("")
  }

  // field names in this section
  const fieldNames = [
    "LEADCF14",
    "LEADCF7",
    "LEADCF6",
    "LEADCF9",
    "LEADCF8",
    "LEADCF11",
    "LEADCF10",
  ]

  return (
    <div className="grid-x grid-margin-x">
      <div className="cell large-6">
        <div className="brief__main" ref={formRef}>
          <div className="briefStep_content">
            <Text content={props.content} />
          </div>

          <div className="briefStep_form">
            <InputField
              formFunctions={props.formFunctions}
              name="LEADCF14"
              label="Headline"
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("LEADCF14") !== -1}
            />

            <InputField
              formFunctions={props.formFunctions}
              name="LEADCF7"
              label="Tagline"
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("LEADCF7") !== -1}
            />

            <InputField
              formFunctions={props.formFunctions}
              name="LEADCF6"
              label="Brand Platform"
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("LEADCF6") !== -1}
            />

            <InputField
              formFunctions={props.formFunctions}
              name="LEADCF9"
              label="First Feature"
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("LEADCF9") !== -1}
            />

            <InputField
              formFunctions={props.formFunctions}
              name="LEADCF8"
              label="Second Feature"
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("LEADCF8") !== -1}
            />

            <InputField
              formFunctions={props.formFunctions}
              name="LEADCF11"
              label="Third Feature"
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("LEADCF11") !== -1}
            />

            <TextareaField
              formFunctions={props.formFunctions}
              name="LEADCF10"
              label="Other Copy"
              rows={4}
              handleOpenTip={handleOpenTip}
              hasTooltip={fieldTipTargets.indexOf("LEADCF10") !== -1}
            />
          </div>

          <BriefStepButtons
            handleBackStep={props.handleBackStep}
            handleNextStep={nextStep}
          />
        </div>
      </div>

      <div className="cell large-6">
        <div className="brief__sidebar">
          <AttachFieldTips
            fieldNames={fieldNames}
            fieldTips={fieldTips}
            activeTip={activeTip}
            tipOffset={tipOffset}
            handleCloseTip={handleCloseTip}
          />
        </div>
      </div>
    </div>
  )
}

BriefMessaging.defaultProps = defaultProps

export default BriefMessaging
