import React, { useState, useEffect } from "react"

import { Controller } from "react-hook-form"

import Select, { components } from "react-select"

import * as utils from "../../utils"

import CaretDown from "../../images/svg/icon_caret-down.svg"

interface Option {
  value: string
  label: string
}

interface SelectFieldProps {
  formFunctions: any
  name?: string
  label: string
  options: Option[]
  placeholder?: string
  value?: string
  required?: boolean
  hideLabel?: boolean
  handleOpenTip?: (value: string, offsetTop: number) => void | undefined
  hasTooltip?: boolean
}

const defaultProps = {
  name: undefined,
  placeholder: "select",
  required: false,
  hideLabel: false,
  handleOpenTip: undefined,
  hasTooltip: false,
  value: undefined
}

const SelectField: React.FC<SelectFieldProps> = props => {
  // react-hook-form
  const control = props.formFunctions.control
  const errors = props.formFunctions.errors

  // state
  const [showValidation, setShowValidation] = useState(false)

  // effects
  useEffect(() => {
    if (!showValidation && Object.keys(errors).length) {
      setShowValidation(true)
    }
  }, [errors])

  // events
  const openTip = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const offsetTop =
      document.getElementById(input_id)?.getBoundingClientRect().top || 0
    if (props.handleOpenTip) props.handleOpenTip(input_name, offsetTop)
  }

  const input_name = props.name || props.label
  const input_id = input_name.split(" ").join("_")
  
  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDown fill="#34A853" />
      </components.DropdownIndicator>
    )
  }

  return (
    <div className="form-field">
      <label htmlFor={input_id}>
        {props.hideLabel ? (
          <span className="show-for-sr">{props.label}</span>
        ) : (
          <>{props.label}</>
        )}
      </label>
      <Controller
        control={control}
        name={input_name}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <div className="field__input">
            <Select
              id={input_id}
              className="select"
              name={name}
              options={props.options}
              styles={utils.customSelectStyles}
              components={{ DropdownIndicator }}
              defaultValue={props.options.filter(option =>
                option.value === props.value)}
              placeholder={props.placeholder}
              value={value?.value}
              onChange={value => onChange(value?.value)}
              onBlur={onBlur}
            //defaultMenuIsOpen={true}
            />
          </div>
        )}
        rules={{
          required: props.required ? `${props.label} is required` : false,
        }}
      />

      {errors.hasOwnProperty(input_name) && (
        <>
          <p className="error-msg">{errors[input_name].message}</p>
          <span className="field-error" />
        </>
      )}

      {showValidation && !errors.hasOwnProperty(input_name) && (
        <span className="field-valid" />
      )}

      {!showValidation && props.hasTooltip && (
        <button className="field-tip" onClick={openTip} />
      )}
    </div>
  )
}

SelectField.defaultProps = defaultProps

export default SelectField
