import React, { useState, useEffect } from "react"

interface InputRangeFieldProps {
  formFunctions: any
  type?: string
  label: string
  startName?: string
  startLabel: string
  startPlaceholder?: string
  startValue?: string
  endName?: string
  endLabel: string
  endPlaceholder?: string
  endValue?: string
  required?: boolean
  min?: number
  max?: number
  hideLabel?: boolean
  handleOpenTip?: (value: string, offsetTop: number) => void | undefined
  hasTooltip?: boolean
}

const defaultProps = {
  type: "text",
  startName: undefined,
  startPlaceholder: "",
  endName: undefined,
  endPlaceholder: "",
  required: false,
  min: undefined,
  max: undefined,
  hideLabel: false,
  handleOpenTip: undefined,
  hasTooltip: false,
  startValue: undefined,
  endValue: undefined,
}

const InputRangeField: React.FC<InputRangeFieldProps> = props => {
  // react-hook-form
  const register = props.formFunctions.register
  const errors = props.formFunctions.errors

  // state
  const [showValidation, setShowValidation] = useState(false)

  // effects
  useEffect(() => {
    if (!showValidation && Object.keys(errors).length) {
      setShowValidation(true)
    }
  }, [errors])

  // events
  const openTip = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const offsetTop =
      document.getElementById(startInput_id)?.getBoundingClientRect().top || 0
    if (props.handleOpenTip) props.handleOpenTip(startInput_name, offsetTop)
  }

  const startInput_name = props.startName || props.startLabel
  const startInput_id = startInput_name.split(" ").join("_")

  const endInput_name = props.endName || props.endLabel
  const endInput_id = endInput_name.split(" ").join("_")

  return (
    <div className="form-field">
      <label htmlFor={startInput_id}>
        {props.hideLabel ? (
          <span className="show-for-sr">{props.label}</span>
        ) : (
          <>{props.label}</>
        )}
      </label>
      <div className="field__input">
        <div className="grid-x align-middle">
          <div className="cell auto">
            <input
              id={startInput_id}
              type={props.type}
              min={props.min}
              max={props.max}
              defaultValue={props.startValue}
              placeholder={props.startPlaceholder}
              className={errors && errors[startInput_name] && "error"}
              {...register(startInput_name, {
                required: props.required
                  ? `${props.startLabel} is required`
                  : false,
                min: props.min ? props.min : undefined,
                max: props.max ? props.max : undefined,
                pattern: getPattern(props.type),
              })}
            />
          </div>

          <div className="cell shrink">
            <span
              style={{ fontWeight: 800, color: "#34a853", padding: "0 10px" }}
            >
              ~
            </span>
          </div>

          <div className="cell auto">
            <input
              id={endInput_id}
              type={props.type}
              min={props.min}
              max={props.max}
              defaultValue={props.endValue}
              placeholder={props.endPlaceholder}
              className={errors && errors[endInput_name] && "error"}
              {...register(endInput_name, {
                required: props.required
                  ? `${props.endLabel} is required`
                  : false,
                min: props.min ? props.min : undefined,
                max: props.max ? props.max : undefined,
                pattern: getPattern(props.type),
              })}
            />
          </div>
        </div>
      </div>

      {errors.hasOwnProperty(startInput_name) && (
        <p className="error-msg">{errors[startInput_name].message}</p>
      )}

      {errors.hasOwnProperty(endInput_name) && (
        <p className="error-msg">{errors[endInput_name].message}</p>
      )}

      {(errors.hasOwnProperty(startInput_name) ||
        errors.hasOwnProperty(endInput_name)) && (
        <span className="field-error" />
      )}

      {showValidation &&
        !errors.hasOwnProperty(startInput_name) &&
        !errors.hasOwnProperty(endInput_name) && (
          <span className="field-valid" />
        )}

      {!showValidation && props.hasTooltip && (
        <button className="field-tip" onClick={openTip} />
      )}
    </div>
  )
}

InputRangeField.defaultProps = defaultProps

export default InputRangeField

const getPattern = (type: string | undefined) => {
  if (type === "email") {
    return {
      value:
        /(?:[A-Za-z0-9!#$%&amp;&#039;*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&amp;&#039;*+/=?^_`{|}~-]+)*|&quot;(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*&quot;)@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
      message: "Please enter a valid email",
    }
  }

  return null
}
