import React from "react"

interface TagListProps {
  tags: string[] | null
  activeTag?: string | null
  className?: string
  handleTagSelect?: (tag: string) => void
  aos?: string | undefined
  aosDelay?: number | undefined
  aosAnchor?: string | undefined
}

const defaultProps = {
  activeTag: undefined,
  className: "",
  handleTagSelect: () => {},
  aos: undefined,
  aosDelay: undefined,
  aosAnchor: undefined,
}

const TagList: React.FC<TagListProps> = props => {
  if (!props.tags) return null

  const selectTag = (tag: string) => {
    if (props.handleTagSelect) {
      props.handleTagSelect(tag)
    }
  }

  return (
    <div className={`tagList ${props.className}`}>
      <div className={`grid-x`}>
        {props.tags.map((tag: string, index: number) => (
          <div key={Math.random()} className="cell shrink tagList__tag">
            <button
              className={`tagList__button ${
                props.activeTag === tag ? "active" : ""
              }`}
              onClick={() => selectTag(tag)}
              data-aos={props.aos}
              data-aos-delay={
                props.aosDelay ? props.aosDelay + index * 100 : undefined
              }
              data-aos-anchor={props.aosAnchor}
            >
              {tag}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

TagList.defaultProps = defaultProps

export default TagList
