import React from "react"

import PhoneIcon from "../../images/svg/icon_phone.svg"
import MailIcon from "../../images/svg/icon_mail.svg"

interface BasicDesktopProps {}

const BasicDesktop: React.FC<BasicDesktopProps> = () => {
  return (
    <div className="grid-x align-middle align-right header__deskMenu">
      <div className="cell shrink">
        <PhoneIcon fill="#34a853" />
      </div>
      <div className="cell shrink">
        <a href="tel:+61291386220">+61 2 9138 6220</a>
      </div>
      <div className="cell shrink">
        <MailIcon fill="#34a853" />
      </div>
      <div className="cell shrink">
        <a href="mailto:hello@aglo.co">hello@aglo.co</a>
      </div>
    </div>
  )
}

export default BasicDesktop
